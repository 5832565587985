import { BffApiUpdateItemError } from '../../../../types';
import {
  MessageFileAttachmentID,
  CreateMessageFromAiDetectProductsSearchCacheKeys
} from '../../messagesTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { ProjectID } from '../../../projects/projectsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MessageBffUrl } from '../../MessageBffUrl';

interface CreateMessageFromAiDetectProductsSearchOptions {
  cacheKeys?: CreateMessageFromAiDetectProductsSearchCacheKeys;
}

export type CreateMessageFromAiDetectProductsSearchInput = {
  projectId?: ProjectID;
  taskId?: TaskID;
  fileAttachmentId?: MessageFileAttachmentID;
};

export type CreateMessageFromAiDetectProductsSearchError =
  BffApiUpdateItemError;

function useCreateMessageFromAiDetectProductsSearch<
  CreateMessageFromAiDetectProductsSearchResponseType
>({ cacheKeys }: CreateMessageFromAiDetectProductsSearchOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    CreateMessageFromAiDetectProductsSearchInput,
    CreateMessageFromAiDetectProductsSearchResponseType,
    CreateMessageFromAiDetectProductsSearchError
  >({ cacheKeys, url: MessageBffUrl.createFromAiDetectProductsSearch() });

  return {
    createMessageFromAiDetectProductsSearchData: postQueryData,
    createMessageFromAiDetectProductsSearchError: postQueryError,
    createMessageFromAiDetectProductsSearchLoading: postQueryLoading,
    createMessageFromAiDetectProductsSearchErrorMessage: postQueryErrorMessage,
    createMessageFromAiDetectProductsSearch: postQuery,
    createMessageFromAiDetectProductsSearchReset: postQueryReset
  };
}

export default useCreateMessageFromAiDetectProductsSearch;
