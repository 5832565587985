import React, { useCallback } from 'react';
import first from 'lodash/first';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { FetchMessagesCacheKey } from '../../../messagesTypes';
import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';
import { useCreateMessageFromAiDetectProductsSearch } from '../../../hooks/useCreateMessageFromAiDetectProductsSearch';

import { DropzoneModalButton } from '../../../../../helpers/dropzone/DropzoneModalButton';
import { Translate } from '../../../../../helpers/Translate';
import { Loading } from '../../../../../helpers/Loading';

import { words, aiSearchKeys } from '../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface CreateMessageFromAiDetectProductsSearchModalButtonProps {
  taskId?: TaskID;
  projectId?: ProjectID;
  cacheKeys?: FetchMessagesCacheKey[];
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}

const initialValueFileAttachments = [];

function CreateMessageFromAiDetectProductsSearchModalButton({
  taskId,
  projectId,
  cacheKeys,
  disabled,
  icon,
  iconClassName,
  className,
  i18nText,
  tooltipI18nText
}: CreateMessageFromAiDetectProductsSearchModalButtonProps) {
  const {
    createMessageFromAiDetectProductsSearch,
    createMessageFromAiDetectProductsSearchErrorMessage,
    createMessageFromAiDetectProductsSearchLoading
  } = useCreateMessageFromAiDetectProductsSearch<unknown>({
    cacheKeys
  });

  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.success
  });

  const handleSubmit = useCallback<(value: string[]) => Promise<unknown>>(
    (value) => {
      const fileAttachmentId = first(value);
      return createMessageFromAiDetectProductsSearch({
        taskId,
        projectId,
        fileAttachmentId
      }).then(() => showToastI18nNotification());
    },
    [
      taskId,
      projectId,
      showToastI18nNotification,
      createMessageFromAiDetectProductsSearch
    ]
  );

  return (
    <DropzoneModalButton
      beforeDropzone={
        <div>
          {createMessageFromAiDetectProductsSearchLoading ? (
            <div className="p-4">
              <Loading addClass="w-full" />
            </div>
          ) : (
            <div className="text-sm mb-4">
              <Translate id={aiSearchKeys.pleaseUploadASinglePhoto} />
            </div>
          )}
        </div>
      }
      className={className}
      disabled={disabled}
      i18nSubmitText={aiSearchKeys.search}
      i18nText={i18nText}
      i18nTitle={aiSearchKeys.aiPoweredSearchDetect}
      icon={icon}
      iconClassName={iconClassName}
      maxFiles={1}
      onSubmit={handleSubmit}
      preventMaxFilesOverload
      resetOnClose
      submitDisabled={createMessageFromAiDetectProductsSearchLoading}
      submitErrorMessage={createMessageFromAiDetectProductsSearchErrorMessage}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.TOP}
      type="fileAttachments"
      value={initialValueFileAttachments}
      withoutDropzoneArea={createMessageFromAiDetectProductsSearchLoading}
      withoutTabs
    />
  );
}

export default CreateMessageFromAiDetectProductsSearchModalButton;
