import {
  getDocument,
  GlobalWorkerOptions,
  version
} from 'pdfjs-dist/legacy/build/pdf.mjs';

async function pdfToImageBase64(
  pdfPath: string,
  imageMimeType = 'image/jpeg',
  imageQuality = 0.5
): Promise<string[]> {
  try {
    GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.mjs`;

    const pdfDoc = await getDocument(pdfPath).promise;

    const totalPagesCount = pdfDoc.numPages;

    const base64Images: string[] = [];

    for (let pageNumber = 1; pageNumber <= totalPagesCount; pageNumber++) {
      const page = await pdfDoc.getPage(pageNumber);

      const viewport = page.getViewport({ scale: 1.5 });

      const canvas = document.createElement('canvas');

      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({
        canvasContext: context,
        viewport: viewport
      }).promise;

      const imgData = canvas.toDataURL(imageMimeType, imageQuality);

      base64Images.push(imgData);

      canvas.remove();
    }

    return base64Images;
  } catch (error) {
    console.error('Error while converting PDF:', error);

    return null;
  }
}

export default pdfToImageBase64;
