import React from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';

import {
  TooltipIconBooleanFieldButton,
  IconTooltipBooleanFieldButtonRequiredProps
} from './components/TooltipIconBooleanFieldButton';

interface TooltipIconBooleanFieldProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  disabled?: boolean;
}

function TooltipIconBooleanField<T>({
  control,
  disabled,
  name,
  yesClassName,
  noClassName,
  yesIcon,
  yesIconClassName,
  noIconClassName,
  noIcon,
  noTooltipI18nText,
  yesTooltipI18nText
}: TooltipIconBooleanFieldProps<T> &
  IconTooltipBooleanFieldButtonRequiredProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TooltipIconBooleanFieldButton
          value={value as boolean}
          disabled={disabled}
          yesClassName={yesClassName}
          noClassName={noClassName}
          yesIcon={yesIcon}
          noIcon={noIcon}
          yesIconClassName={yesIconClassName}
          noIconClassName={noIconClassName}
          onChange={onChange}
          noTooltipI18nText={noTooltipI18nText}
          yesTooltipI18nText={yesTooltipI18nText}
        />
      )}
    />
  );
}

export default TooltipIconBooleanField;
