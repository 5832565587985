import {
  FetchSelectedMaterialsFilters,
  FetchSelectedMaterialsSort,
  FetchSelectedMaterialsLimit,
  FetchSelectedMaterialsGqlQuery,
  FetchSelectedMaterialsCacheKey
} from '../../selectedMaterialsTypes';

import {
  INITIAL_SELECTED_MATERIALS_FILTERS,
  INITIAL_SELECTED_MATERIALS_LIMIT,
  INITIAL_SELECTED_MATERIALS_SORT
} from '../../selectedMaterialsConstants';

import {
  ReadInfiniteIndexQueryBaseNodeType,
  useReadInfiniteIndexQuery
} from '../../../common/hooks/base/reactQuery/useReadInfiniteIndexQuery';

interface SelectedMaterialsOptions {
  cacheKey: FetchSelectedMaterialsCacheKey;
  query: FetchSelectedMaterialsGqlQuery;
  initialFilters?: FetchSelectedMaterialsFilters;
  initialSort?: FetchSelectedMaterialsSort;
  initialLimit?: FetchSelectedMaterialsLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'selectedMaterials';

function useSelectedMaterials<
  SelectedMaterialItemType extends ReadInfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_SELECTED_MATERIALS_FILTERS,
  initialSort = INITIAL_SELECTED_MATERIALS_SORT,
  initialLimit = INITIAL_SELECTED_MATERIALS_LIMIT,
  options = {}
}: SelectedMaterialsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    changeItemsFilters,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems
  } = useReadInfiniteIndexQuery<SelectedMaterialItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    selectedMaterialsData: data,
    selectedMaterials: items,
    selectedMaterialsError: itemsError,
    selectedMaterialsTotalCount: itemsTotalCount,
    selectedMaterialsFetched: isFetched,
    selectedMaterialsLoading: isLoading,
    selectedMaterialsFetchingNextPage: isFetchingNextPage,
    selectedMaterialsIsPlaceholderData: isPlaceholderData,
    selectedMaterialsFilters: currentFilters,
    selectedMaterialsSort: currentSort,
    selectedMaterialsPage: currentPage,
    selectedMaterialsLimit: currentLimit,
    hasNextSelectedMaterialsPage: hasNextPage,
    updateSelectedMaterialCache: updateItemCache,
    loadMoreSelectedMaterials: loadMoreItems,
    filterSelectedMaterials: filterItems,
    clearSelectedMaterialsFilters: clearItemsFilters,
    sortSelectedMaterials: sortItems,
    limitSelectedMaterials: limitItems,
    changeSelectedMaterialsFilters: changeItemsFilters
  };
}

export default useSelectedMaterials;
