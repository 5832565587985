import React, { useState } from 'react';
import size from 'lodash/size';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { ItemMessagesListMessageColorsMessage } from './ItemMessagesListMessageColors.types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID,
  OnEditMessageAction
} from '../../../../../messagesTypes';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { ColorButtons } from '../../../../../../../helpers/colors/ColorButtons';

import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';

import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

type ItemMessagesListMessageColorsProps = {
  message: ItemMessagesListMessageColorsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  reverse?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withEditMessage?: boolean;
  withoutDropdownMenu?: boolean;
  onEditMessage?: OnEditMessageAction;
} & CheckableMessagesProps;

function ItemMessagesListMessageColors({
  message,
  messagesCacheKey,
  onReplyMessage,
  reverse,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  withEditMessage,
  withoutDropdownMenu,
  onEditMessage,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListMessageColorsProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const isVisibleForClient = !message.body && message.visibleForClient;

  const withMenu =
    isEmpty(message.body) &&
    isEmpty(message.todoItems) &&
    isEmpty(message.forwardedMessage?.body) &&
    isEmpty(message.fileAttachments);

  if (size(message.colors) === 0) {
    return null;
  }

  return (
    <div
      className={cl('flex mt-0.5', {
        'justify-end': reverse
      })}
    >
      <div
        ref={setReferenceTooltipElement}
        className={cl('text-sm overflow-hidden rounded-xl ', {
          'rounded-tr rounded-br': reverse,
          'rounded-tl rounded-bl': !reverse
        })}
      >
        <ColorButtons
          colors={message.colors}
          isVisibleForClient={isVisibleForClient}
        />
      </div>
      {withMenu && (
        <ItemMessagesListMessageMenu
          message={message}
          messagesCacheKey={messagesCacheKey}
          onReplyMessage={onReplyMessage}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          withEditMessage={withEditMessage}
          withoutDropdownMenu={withoutDropdownMenu}
          onEditMessage={onEditMessage}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
          isLeft={reverse}
        />
      )}
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </div>
  );
}

export default ItemMessagesListMessageColors;
