import React, { useMemo } from 'react';
import some from 'lodash/some';
import compact from 'lodash/compact';

import { PositionStyleEnum } from '../../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import {
  ItemMessagesListMessageImageFileAttachmentButtonsAttachment,
  ItemMessagesListMessageImageFileAttachmentButtonsMessage
} from './ItemMessagesListMessageImageFileAttachmentButtons.types';
import { SendImagesToWhiteboardPageModalButtonImage } from '../../../../../../../../whiteboards/components/forms/SendImagesToWhiteboardPageForm';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { RemoveFileAttachmentButton } from '../../../../../../../../fileAttachments/components/buttons/RemoveFileAttachmentButton';
import { DownloadFileAttachmentButton } from '../../../../../../../../fileAttachments/components/buttons/DownloadFileAttachmentButton';
import { EditImageAndSendToProjectModalButton } from '../../../../../../../../projects/components/modalButtons/EditImageAndSendToProjectModalButton';
import { EditImageAndSendToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/EditImageAndSendToTaskModalButton';
import { SendImagesToWhiteboardPageDynamicModalButton } from '../../../../../../../../whiteboards/components/buttons/SendImagesToWhiteboardPageModalButton';
import { SubmitSourceFilesToTaskModalButton } from '../../../../../../../../tasks/components/modalButtons/SubmitSourceFilesToTaskModalButton';

import { PopoverPlacement } from '../../../../../../../../../helpers/Popover/popoverConstants';
import { TogglePreventModalCloseAction } from '../../../../../../../../../helpers/modals/modalsTypes';
import { TooltipDropdownHelper } from '../../../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { TooltipPlacement } from '../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import {
  stringsKeys,
  tasksKeys,
  words
} from '../../../../../../../../../locales/keys';

import { MessagesPermissions } from '../../../../../../../messagesConstants';

interface ItemMessagesListMessageImageFileAttachmentButtonsProps {
  message: ItemMessagesListMessageImageFileAttachmentButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  attachment: ItemMessagesListMessageImageFileAttachmentButtonsAttachment;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}

function ItemMessagesListMessageImageFileAttachmentButtons({
  message,
  messagesCacheKey,
  attachment,
  togglePreventModalClose
}: ItemMessagesListMessageImageFileAttachmentButtonsProps) {
  const currentUser = useCurrentUser();

  const withEditImageButton =
    Boolean(message.task?.nanoId || message.project?.nanoId) &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_EDIT_BUTTON
    );

  const withDownloadButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_DOWNLOAD_FILE_ATTACHMENT_BUTTON
    );

  const withRemoveButton =
    attachment.uuid &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_REMOVE_FILE_ATTACHMENT_BUTTON
    );

  const withSubmitSourceFilesButton =
    attachment.uuid &&
    message.task?.nanoId &&
    some(
      compact([
        MessagesPermissions.READ_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON,
        currentUser.nanoId === message.user.nanoId
          ? MessagesPermissions.READ_SELF_MESSAGE_FILE_ATTACHMENT_SUBMIT_SOURCE_FILES_BUTTON
          : null
      ]),
      (action) => currentUser.hasPermissions(action)
    );

  const withSendToWhiteboardButton =
    attachment.uuid &&
    (message.project?.nanoId || message.task?.nanoId) &&
    currentUser.hasPermissions(
      MessagesPermissions.READ_MESSAGE_SEND_IMAGE_TO_WHITEBOARD_BUTTON
    );

  const images = useMemo<SendImagesToWhiteboardPageModalButtonImage[]>(() => {
    if (!withSendToWhiteboardButton) {
      return [];
    }

    return [
      {
        fileUrl: attachment.file,
        height: attachment.height,
        name: attachment.name,
        width: attachment.width,
        fileAttachmentId: attachment.id
      }
    ];
  }, [attachment, withSendToWhiteboardButton]);

  if (
    !withEditImageButton &&
    !withDownloadButton &&
    !withRemoveButton &&
    !withSubmitSourceFilesButton &&
    !withSendToWhiteboardButton
  ) {
    return null;
  }

  const projectNanoId = message.project?.nanoId;

  return (
    <div className="absolute top-2 right-2 opacity-0 group-hover/item:opacity-100 bg-white dark:bg-gray-800 rounded-md p-0.5 flex gap-0.5 shadow">
      <TooltipSingletonSourceWrapper placement={TooltipPlacement.TOP} withArrow>
        {withEditImageButton &&
        message.task?.nanoId &&
        !message.project?.nanoId ? (
          <EditImageAndSendToTaskModalButton
            file={attachment.file}
            taskNanoId={message.task.nanoId}
            className="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            iconClassName="h-5 w-5"
            icon={IconsEnum.PENCIL_ALT_OUTLINE}
            tooltipI18nText={words.edit}
            toggleImageEditorBackdrop={togglePreventModalClose}
            tooltipPlacement={TooltipPlacement.TOP}
            tooltipSingleton
          />
        ) : null}
        {withEditImageButton &&
        !message.task?.nanoId &&
        message.project?.nanoId ? (
          <EditImageAndSendToProjectModalButton
            file={attachment.file}
            projectNanoId={message.project.nanoId}
            className="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            iconClassName="h-5 w-5"
            icon={IconsEnum.PENCIL_ALT_OUTLINE}
          />
        ) : null}
        <TooltipDropdownHelper
          buttonClassName="py-1 pl-1 pr-1 rounded-md bg-white dark:bg-gray-800 leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
          icon={IconsEnum.DOTS_HORIZONTAL_OUTLINE}
          tooltipSingleton
          popoverPositionStyle={PositionStyleEnum.fixed}
          tooltipI18nText={words.more}
          dropdownPlacement={PopoverPlacement.AUTO_END}
          closeOnOutOfView
        >
          {withDownloadButton && (
            <DownloadFileAttachmentButton
              fileAttachmentUuid={attachment.uuid}
              className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
              i18nText={words.download}
            />
          )}
          {withSubmitSourceFilesButton && message.task?.nanoId ? (
            <SubmitSourceFilesToTaskModalButton
              taskNanoId={message.task.nanoId}
              className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
              i18nText={tasksKeys.submitAsSourceFile}
              initialFile={attachment}
            />
          ) : null}
          {withRemoveButton && (
            <RemoveFileAttachmentButton
              cacheKeys={[messagesCacheKey]}
              fileAttachmentUuid={attachment.uuid}
              className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
              i18nText={words.remove}
            />
          )}
          {withSendToWhiteboardButton && (
            <SendImagesToWhiteboardPageDynamicModalButton
              cacheKeys={[messagesCacheKey]}
              i18nText={stringsKeys.sendToWhiteboard}
              images={images}
              isProjectWhiteboard={!!projectNanoId}
              projectNanoId={projectNanoId || message.task?.project?.nanoId}
              taskNanoId={message.task?.nanoId}
              className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
            />
          )}
        </TooltipDropdownHelper>
      </TooltipSingletonSourceWrapper>
    </div>
  );
}

export default ItemMessagesListMessageImageFileAttachmentButtons;
