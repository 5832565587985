import React, { useCallback } from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  MessageClassifications,
  MessageID,
  MessageUUID
} from '../../../../../messagesTypes';
import { ItemMessagesListMessageRepliedMessageMessage } from './ItemMessagesListMessageRepliedMessage.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListMessageRepliedMessageColors } from './components/ItemMessagesListMessageRepliedMessageColors';
import { ItemMessagesListMessageRepliedMessageTodoItems } from './components/ItemMessagesListMessageRepliedMessageTodoItems';
import { ItemMessagesListMessageRepliedMessageAttachments } from './components/ItemMessagesListMessageRepliedMessageAttachments';

import { Icon } from '../../../../../../../helpers/Icon';
import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

type ItemMessagesListMessageRepliedMessageProps = {
  message: ItemMessagesListMessageRepliedMessageMessage;
  reverse?: boolean;
  jumpToMessage?: (message: { id: MessageID; uuid: MessageUUID }) => void;
  markedUnhappyMessages?: boolean;
};

function ItemMessagesListMessageRepliedMessage({
  message,
  reverse,
  jumpToMessage,
  markedUnhappyMessages
}: ItemMessagesListMessageRepliedMessageProps) {
  const currentUser = useCurrentUser();

  const unhappy =
    (message.repliedMessage?.classification ===
      MessageClassifications.UNHAPPY ||
      message.repliedMessage?.classification ===
        MessageClassifications.MOST_LIKELY_UNHAPPY) &&
    markedUnhappyMessages &&
    !message.repliedMessage?.aiNotRelevantAt;

  const handleJumpToMessage = useCallback(() => {
    jumpToMessage({
      id: message.repliedMessage?.id,
      uuid: message.repliedMessage?.uuid
    });
  }, [jumpToMessage, message.repliedMessage?.id, message.repliedMessage?.uuid]);

  if (!message.repliedMessage) {
    return null;
  }

  return (
    <div
      className={cl(
        reverse
          ? 'flex justify-end ml-12 sm:ml-16 mr-3'
          : 'flex justify-start mr-3 sm:mr-7 ml-3'
      )}
    >
      <div
        className={cl(
          'cursor-pointer flex opacity-75 hover:opacity-90 focus-within:opacity-90',
          {
            '-mb-4': isEmpty(message.repliedMessage.colors),
            'justify-end': reverse
          }
        )}
        onClick={handleJumpToMessage}
      >
        <div className="flex flex-col">
          <div className="text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400">
            <Icon
              icon={IconsEnum.REPLY_OUTLINE}
              className="w-3.5 h-3.5 inline mr-1"
            />
            <Translate id={messagesKeys.replyTo} />{' '}
            {message.repliedMessage.user?.fullName}
          </div>
          <div className="w-full">
            <div
              className={cl('flex', reverse ? 'justify-end' : 'justify-start')}
            >
              <div className="flex flex-col items-end">
                {isEmpty(message.repliedMessage.body) &&
                isEmpty(message.repliedMessage.todoItems) ? null : (
                  <div
                    className={cl(
                      'inline-flex px-3 py-2 rounded-xl relative text-xs max-w-xl',
                      {
                        'border border-gray-200 dark:border-gray-800 rounded-bl':
                          !message.repliedMessage.visibleForClient &&
                          !message.repliedMessage.user?.client,
                        'bg-blue-100 dark:bg-gray-300 dark:text-black':
                          message.repliedMessage.visibleForClient &&
                          !message.repliedMessage.user?.client,
                        'bg-blue-100 border-blue-500 border-l-8 dark:text-black':
                          message.repliedMessage.visibleForClient &&
                          message.repliedMessage.user?.client &&
                          !unhappy,
                        'bg-red-100 border-red-500 border-l-8 dark:text-black':
                          message.repliedMessage.visibleForClient &&
                          message.repliedMessage.user?.client &&
                          unhappy,
                        'pb-6': isEmpty(message.repliedMessage.colors)
                      }
                    )}
                  >
                    {message.repliedMessage.visibleForClient &&
                      message.repliedMessage.user?.client &&
                      unhappy && (
                        <div className="absolute flex -left-6 -top-0.5 text-red-500 rounded-full bg-white dark:bg-gray-900">
                          <Icon icon={IconsEnum.EMOJI_SAD_SOLID} />
                        </div>
                      )}

                    {message.repliedMessage.body && (
                      <div className="text-xs leading-snug line-clamp-2 break-wrap">
                        <MarkdownHelper
                          source={message.repliedMessage.body}
                          className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug"
                          fromMessage
                          linkTarget={
                            currentUser.hasPermissions(
                              MessagesPermissions.READ_MESSAGE_LINK_BLANK
                            )
                              ? '_blank'
                              : undefined
                          }
                        />
                      </div>
                    )}

                    {message.repliedMessage.todoItems && (
                      <ItemMessagesListMessageRepliedMessageTodoItems
                        todoItems={message.repliedMessage.todoItems}
                      />
                    )}
                  </div>
                )}
                {isEmpty(message.repliedMessage.colors) ? null : (
                  <ItemMessagesListMessageRepliedMessageColors
                    message={message.repliedMessage}
                  />
                )}
              </div>
            </div>

            <div
              className={
                reverse
                  ? 'flex mt-1 mb-0.5 justify-end'
                  : 'flex mt-1 mb-0.5 justify-start'
              }
            >
              <ItemMessagesListMessageRepliedMessageAttachments
                message={message}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemMessagesListMessageRepliedMessage;
