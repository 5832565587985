import { memo, useState } from 'react';

import { ProductID } from '../../productsTypes';
import { IconsEnum } from '../../../../assets/icons/types';

import { ProductInfo } from './components/ProductInfo';

import { Icon } from '../../../../helpers/Icon';
import { Tooltip } from '../../../../helpers/tooltips/Tooltip';

import {
  bodyTooltipContainer,
  TooltipPlacement
} from '../../../../helpers/tooltips/tooltipsConstants';

interface ProductTooltipIconProps {
  productId: ProductID;
}
function ProductTooltipIcon({ productId }: ProductTooltipIconProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  return (
    <span
      className="p-1 rounded bg-black bg-opacity-50 text-white pointer-events-auto"
      ref={setReferenceTooltipElement}
    >
      <Icon icon={IconsEnum.CUBES_OUTLINE} className="h-3.5 w-3.5" />

      <Tooltip
        appendTo={bodyTooltipContainer}
        interactive
        lazy
        placement={TooltipPlacement.TOP}
        referenceElement={referenceTooltipElement}
        withArrow
      >
        <ProductInfo productId={productId} />
      </Tooltip>
    </span>
  );
}

export default memo<ProductTooltipIconProps>(ProductTooltipIcon);
