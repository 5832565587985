import React, { Fragment, useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import size from 'lodash/size';

import { MessageFields, MessageID } from '../../../../../../../messagesTypes';
import { ItemCreateMessageFormData } from '../../../../hooks/useItemCreateMessageForm';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import {
  FETCH_REPLIED_FIN_MESSAGE_QUERY,
  FetchRepliedFinMessageQueryResponse
} from '../../../../../../../queries/fetchRepliedFinMessage.query';

import { useFinMessage } from '../../../../../../../hooks/useFinMessage';
import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { PureTooltipIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../../../helpers/Translate';
import { MarkdownHelper } from '../../../../../../../../../helpers/MarkdownHelper';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';

import { MessageCache } from '../../../../../../../MessageCache';
import { MessagesPermissions } from '../../../../../../../messagesConstants';

import { messagesKeys, words } from '../../../../../../../../../locales/keys';

interface ItemCreateMessageRepliedMessageContentProps {
  messageId: MessageID;
  setValue: UseFormSetValue<ItemCreateMessageFormData>;
}

function ItemCreateMessageRepliedMessageContent({
  messageId,
  setValue
}: ItemCreateMessageRepliedMessageContentProps) {
  const currentUser = useCurrentUser();
  const { message, messageError, messageFetched } =
    useFinMessage<FetchRepliedFinMessageQueryResponse>({
      cacheKey: MessageCache.showCacheKey(),
      query: FETCH_REPLIED_FIN_MESSAGE_QUERY,
      uuid: messageId
    });

  const handleRemoveFile = useCallback<() => void>(
    () => setValue(MessageFields.REPLIED_MESSAGE_ID, null),
    [setValue]
  );

  const sizeAttachments =
    size(message?.fileAttachments) +
    size(message?.selectedLifestyles) +
    size(message?.selectedProducts) +
    size(message?.selectedMaterials) +
    size(message?.colors) +
    (size(message?.todoItems) > 0 ? 1 : 0);

  return (
    <div className="flex col-span-full mb-1.5">
      <LoadingSkeleton loaded={messageFetched} count={1} className="w-full">
        <AlertMessage message={messageError} />

        <div className="flex-1 text-xs">
          <div className="mb-0.5 ml-2 text-gray-600 dark:text-gray-400">
            <Icon
              icon={IconsEnum.REPLY_SOLID}
              className="w-3.5 h-3.5 inline mr-1"
            />
            <Translate id={messagesKeys.replyTo} /> {message?.user?.fullName}
          </div>
          <div className="pl-3 py-0.5 border-l-4 line-clamp-2 break-wrap">
            {sizeAttachments > 0 && (
              <Fragment>
                <Icon
                  icon={IconsEnum.PAPER_CLIP_SOLID}
                  className="w-3.5 h-3.5 inline mr-0.5"
                />

                <span className="italic lowercase">
                  {sizeAttachments} <Translate id={words.attachments} />
                </span>
              </Fragment>
            )}

            {sizeAttachments > 0 && message?.body && (
              <span className="mx-0.5">•</span>
            )}

            <div>
              <MarkdownHelper
                source={
                  message?.body ||
                  message?.forwardedMessage?.body ||
                  message?.repliedMessage?.body
                }
                className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug"
                fromMessage
                linkTarget={
                  currentUser.hasPermissions(
                    MessagesPermissions.READ_MESSAGE_LINK_BLANK
                  )
                    ? '_blank'
                    : undefined
                }
              />
            </div>
          </div>
        </div>

        <div>
          <PureTooltipIconButtonHelper
            className="py-1 pl-1 pr-1 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.X}
            iconClassName="h-5 w-5"
            tooltipI18nText={messagesKeys.cancelReply}
            onClick={handleRemoveFile}
          />
        </div>
      </LoadingSkeleton>
    </div>
  );
}

export default ItemCreateMessageRepliedMessageContent;
