import { BffApiUpdateItemError } from '../../../../types';
import {
  MessageFileAttachmentID,
  CreateMessageFromAiProductsSearchCacheKeys
} from '../../messagesTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { ProjectID } from '../../../projects/projectsTypes';
import { ProductCategoryID } from '../../../products/productsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MessageBffUrl } from '../../MessageBffUrl';

interface CreateMessageFromAiProductsSearchOptions {
  cacheKeys?: CreateMessageFromAiProductsSearchCacheKeys;
}

export type CreateMessageFromAiProductsSearchInput = {
  projectId?: ProjectID;
  taskId?: TaskID;
  fileAttachmentId?: MessageFileAttachmentID;
  productCategoryId?: ProductCategoryID;
};

export type CreateMessageFromAiProductsSearchError = BffApiUpdateItemError;

function useCreateMessageFromAiProductsSearch<
  CreateMessageFromAiProductsSearchResponseType
>({ cacheKeys }: CreateMessageFromAiProductsSearchOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    CreateMessageFromAiProductsSearchInput,
    CreateMessageFromAiProductsSearchResponseType,
    CreateMessageFromAiProductsSearchError
  >({ cacheKeys, url: MessageBffUrl.createFromAiProductsSearch() });

  return {
    createMessageFromAiProductsSearchData: postQueryData,
    createMessageFromAiProductsSearchError: postQueryError,
    createMessageFromAiProductsSearchLoading: postQueryLoading,
    createMessageFromAiProductsSearchErrorMessage: postQueryErrorMessage,
    createMessageFromAiProductsSearch: postQuery,
    createMessageFromAiProductsSearchReset: postQueryReset
  };
}

export default useCreateMessageFromAiProductsSearch;
