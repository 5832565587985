import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import { DropzoneFileAttachmentsHelperRequiredProps } from './DropzoneFileAttachmentsHelper.types';

import {
  FETCH_DROPZONE_FILE_ATTACHMENTS_QUERY,
  FetchDropzoneFileAttachmentsQueryResponse
} from '../../../main/fileAttachments/queries/fetchDropzoneFileAttachments.query';

import { useFileAttachments } from '../../../main/fileAttachments/hooks/useFileAttachments';

import { LoadingSkeleton } from '../../LoadingSkeleton';
import { AlertMessage } from '../../AlertMessage';

import {
  DropzoneHelper,
  DropzoneHelperFiles,
  DropzoneHelperFileIds
} from '../DropzoneHelper';

import { getFileAttachmentDropzoneTab } from './utils/getFileAttachmentDropzoneTab';

import { FileAttachmentCache } from '../../../main/fileAttachments/FileAttachmentCache';

interface DropzoneFileAttachmentsHelperProps {
  value: string[];
  onChange: (value: DropzoneHelperFileIds) => void;
  onChangeFiles?: (files: DropzoneHelperFiles) => void;
}

function DropzoneFileAttachmentsHelper({
  type,
  value,
  disabled,
  withFullscreenDropzone,
  onChange,
  onChangeFiles,
  onFileUploaded
}: DropzoneFileAttachmentsHelperRequiredProps &
  DropzoneFileAttachmentsHelperProps) {
  const { fileAttachments, fileAttachmentsError, fileAttachmentsFetched } =
    useFileAttachments<FetchDropzoneFileAttachmentsQueryResponse>({
      cacheKey: FileAttachmentCache.fileAttachmentsDropzoneHelper(),
      query: FETCH_DROPZONE_FILE_ATTACHMENTS_QUERY,
      initialFilters: {
        id: {
          in: value || []
        }
      },
      initialLimit: 1000,
      options: {
        enabled: !isEmpty(value),
        cacheTime: 0,
        enabledPlaceholder: false
      }
    });

  const initialFiles: DropzoneHelperFiles = isEmpty(value)
    ? {}
    : mapValues(keyBy(fileAttachments, 'uuid'), (attachment) => ({
        activeTab: getFileAttachmentDropzoneTab({
          fileAttachment: attachment
        }),
        id: attachment.uuid,
        name: attachment.name,
        file: attachment.file,
        size: attachment.size,
        progresses: { 0: 100 },
        state: 'finished' as const,
        uploadedId: attachment.id
      }));

  return (
    <Fragment>
      <AlertMessage message={fileAttachmentsError} />
      <LoadingSkeleton loaded={isEmpty(value) || fileAttachmentsFetched}>
        <DropzoneHelper
          type={type}
          initialFiles={initialFiles}
          initialFileIds={value}
          disabled={disabled}
          withFullscreenDropzone={withFullscreenDropzone}
          onChange={onChange}
          onChangeFiles={onChangeFiles}
          onFileUploaded={onFileUploaded}
        />
      </LoadingSkeleton>
    </Fragment>
  );
}

export default DropzoneFileAttachmentsHelper;
