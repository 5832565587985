import React, { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import cl from 'classnames';

import { ItemMessagesListMessageFilesMessage } from './ItemMessagesListMessageFiles.types';
import {
  FetchMessagesCacheKey,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID,
  OnEditMessageAction
} from '../../../../../messagesTypes';
import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { ItemMessagesListMessageFile } from '../ItemMessagesListMessageFile';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';

import { Files } from '../../../../../../../utils/Files';
import { sortMessageFileAttachments } from '../../../../../utils/sortMessageFileAttachments';

type ItemMessagesListMessageFilesProps = {
  message: ItemMessagesListMessageFilesMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  onReplyMessage?: (messageId: MessageID) => void;
  reverse?: boolean;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  withEditMessage?: boolean;
  withoutDropdownMenu?: boolean;
  onEditMessage?: OnEditMessageAction;
} & CheckableMessagesProps;

function ItemMessagesListMessageFiles({
  message,
  messagesCacheKey,
  onReplyMessage,
  reverse,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  withEditMessage,
  withoutDropdownMenu,
  onEditMessage,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListMessageFilesProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const fileAttachments = useMemo(
    () =>
      sortMessageFileAttachments(
        message.fileAttachments?.filter(
          (fileAttachment) => !Files.isImage(fileAttachment.file)
        )
      ),
    [message.fileAttachments]
  );

  const isVisibleForClient = !message.body && message.visibleForClient;

  const withMenu =
    isEmpty(message.body) &&
    isEmpty(message.todoItems) &&
    isEmpty(message.forwardedMessage?.body) &&
    size(message.fileAttachments) === size(fileAttachments);

  if (isEmpty(fileAttachments)) {
    return null;
  }

  return (
    <div
      className={cl('flex', {
        'justify-end': reverse
      })}
      ref={setReferenceTooltipElement}
    >
      <div className="p-0.5 -m-0.5 overflow-hidden rounded-2xl max-w-xl">
        <div className="flex flex-wrap gap-1">
          {fileAttachments.map((fileAttachment) => (
            <ItemMessagesListMessageFile
              key={fileAttachment.uuid}
              messagesCacheKey={messagesCacheKey}
              fileAttachment={fileAttachment}
              message={message}
              isVisibleForClient={isVisibleForClient}
              tooltipSingleton
            />
          ))}
        </div>
      </div>
      {withMenu && (
        <ItemMessagesListMessageMenu
          message={message}
          messagesCacheKey={messagesCacheKey}
          onReplyMessage={onReplyMessage}
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          withEditMessage={withEditMessage}
          withoutDropdownMenu={withoutDropdownMenu}
          onEditMessage={onEditMessage}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
          isLeft={reverse}
        />
      )}
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </div>
  );
}

export default ItemMessagesListMessageFiles;
