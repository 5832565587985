import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

export type TodoItemType = {
  name: string;
  done: boolean;
  visibleForClients: boolean;
};

export type TodoItemsType = TodoItemType[];

const emptyTodoItem: TodoItemType = {
  name: '',
  done: false,
  visibleForClients: false
};

function todoItemsFromText(
  text: string,
  visibleForClients = false
): TodoItemsType {
  const textLines = text.split(/(?:\r?\n)+/);

  const textLinesWithoutEmptyLines =
    !isEmpty(textLines) && last(textLines) === ''
      ? textLines.slice(0, -1)
      : textLines;

  return textLinesWithoutEmptyLines.map((name, index) => ({
    ...emptyTodoItem,
    name,
    order: index + 1,
    visibleForClients
  }));
}

export default todoItemsFromText;
