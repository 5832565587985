import React, { memo } from 'react';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { DateTimeHelper } from '../../../../../../../helpers/DateTimeHelper';

import { MessageCreatedAt, MessageUUID } from '../../../../../messagesTypes';

interface ItemMessagesListMessageCreatedAtProps {
  uuid: MessageUUID;
  createdAt: MessageCreatedAt;
  onClick: () => void;
}

function ItemMessagesListMessageCreatedAt({
  uuid,
  createdAt,
  onClick
}: ItemMessagesListMessageCreatedAtProps) {
  return (
    <div
      id={`message-${uuid}-created-at`}
      className="text-center text-xs text-gray-500 dark:text-gray-400 mt-6 mb-4"
    >
      <ButtonHelper onClick={onClick}>
        <DateTimeHelper date={createdAt} />
      </ButtonHelper>
    </div>
  );
}

export default memo<ItemMessagesListMessageCreatedAtProps>(
  ItemMessagesListMessageCreatedAt
);
