import { gql } from 'graphql-request';

import { MayBe } from '../../../types';

import {
  SelectedLifestyleID,
  SelectedLifestyleUUID,
  SelectedLifestyleCreatedAt,
  SelectedLifestyleLifestyleUUID,
  SelectedLifestyleLifestyleName,
  SelectedLifestyleLifestyleNda,
  SelectedLifestyleLifestyleProjectNameUUID,
  SelectedLifestyleLifestyleProjectNameName,
  SelectedLifestyleLifestyleImageUUID,
  SelectedLifestyleLifestyleImageFile,
  SelectedLifestyleUserNanoId,
  SelectedLifestyleUserFullName,
  SelectedLifestyleUserImageUUID,
  SelectedLifestyleUserImageFile,
  SelectedLifestyleLifestyleCategoryLocalizedName,
  SelectedLifestyleUserClient,
  SelectedLifestyleUserCurrentTeamNanoID,
  SelectedLifestyleLifestyleAuthorLocalizedName
} from '../selectedLifestylesTypes';

export interface FetchSelectedLifestylesQueryResponse {
  id: SelectedLifestyleID;
  uuid: SelectedLifestyleUUID;
  createdAt: SelectedLifestyleCreatedAt;
  user: {
    nanoId: SelectedLifestyleUserNanoId;
    fullName: SelectedLifestyleUserFullName;
    client: SelectedLifestyleUserClient;
    currentTeam: MayBe<{
      nanoId: SelectedLifestyleUserCurrentTeamNanoID;
    }>;
    image: {
      uuid: SelectedLifestyleUserImageUUID;
      file: SelectedLifestyleUserImageFile;
    };
  };
  lifestyle: {
    author: {
      localizedName: SelectedLifestyleLifestyleAuthorLocalizedName;
    };
    uuid: SelectedLifestyleLifestyleUUID;
    name: SelectedLifestyleLifestyleName;
    nda: SelectedLifestyleLifestyleNda;
    projectName: {
      uuid: SelectedLifestyleLifestyleProjectNameUUID;
      name: SelectedLifestyleLifestyleProjectNameName;
    };
    category: {
      localizedName: SelectedLifestyleLifestyleCategoryLocalizedName;
    };
    image: {
      uuid: SelectedLifestyleLifestyleImageUUID;
      file: SelectedLifestyleLifestyleImageFile;
    };
  };
}

export const FETCH_SELECTED_LIFESTYLES_QUERY = gql`
  query TaskSelectedLifestyles(
    $filters: SelectedLifestylesFilters
    $sort: [SelectedLifestylesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    selectedLifestyles(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        createdAt
        user {
          nanoId
          fullName
          client
          currentTeam {
            nanoId
          }
          image {
            uuid
            file
          }
        }
        lifestyle {
          author {
            localizedName
          }
          uuid
          name
          nda
          projectName {
            uuid
            name
          }
          category {
            localizedName
          }
          image {
            uuid
            file
          }
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
