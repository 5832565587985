import React from 'react';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../helpers/Icon';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { IconsEnum } from '../../../../../../../assets/icons/types';

function ItemMessagesListMessageImageVisibleForClient() {
  return (
    <CheckPermissions
      action={MessagesPermissions.READ_MESSAGE_VISIBLE_FOR_CLIENT_ICON}
    >
      <span className="p-1 rounded bg-black bg-opacity-50 text-white">
        <Icon icon={IconsEnum.EYE_SOLID} className="h-3.5 w-3.5" />
      </span>
    </CheckPermissions>
  );
}

export default ItemMessagesListMessageImageVisibleForClient;
