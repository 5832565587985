import React, { useMemo, useCallback } from 'react';
import compact from 'lodash/compact';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import { ItemMessagesListMessageWhiteboardAssociatedImageMessage } from './ItemMessagesListMessageWhiteboardAssociatedImage.types';

import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';
import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxItems
} from '../../../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../../../utils/Files';

interface ItemMessagesListMessageWhiteboardAssociatedImageProps {
  message: ItemMessagesListMessageWhiteboardAssociatedImageMessage;
  togglePreventModalClose?: TogglePreventModalCloseAction;
}
function ItemMessagesListMessageWhiteboardAssociatedImage({
  message,
  togglePreventModalClose
}: ItemMessagesListMessageWhiteboardAssociatedImageProps) {
  const lightboxItem = message.fileAttachment;

  const lightboxItems = useMemo<LightboxItems>(
    () => compact([lightboxItem]),
    [lightboxItem]
  );

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems,
    toggleBackdrop: togglePreventModalClose
  });

  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (lightboxItem && Files.isImage(lightboxItem.file)) {
        e.preventDefault();
        handleLightboxOpenOnSlide(lightboxItem);
      }
    },
    [lightboxItem, handleLightboxOpenOnSlide]
  );

  return (
    <div className="relative -ml-1 shrink-0 w-9 h-9 inline-flex overflow-hidden rounded focus-within:ring-base focus-within:ring-offset-0">
      <LinkHelper
        className="absolute inset-0 object-cover focus:outline-none"
        href={lightboxItem?.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className="absolute inset-0 object-cover"
          src={lightboxItem?.file}
          alt={lightboxItem?.name}
          version={FileAttachmentItemImageVersions.Thumb120x120}
        />
      </LinkHelper>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        withFullScreenButton
        withBaseImageTitle
      />
    </div>
  );
}

export default ItemMessagesListMessageWhiteboardAssociatedImage;
