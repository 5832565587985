import React, { useCallback, useEffect, useRef } from 'react';
import size from 'lodash/size';
import find from 'lodash/find';

import {
  DropzoneWrapperHelperFileIds,
  DropzoneWrapperHelperRequiredProps
} from './DropzoneWrapperHelper.types';

import {
  S3MultipartDropzoneWrapper,
  S3MultipartDropzoneWrapperOnFileUploaded
} from '../../dropzone/S3MultipartDropzoneWrapper';

type DropzoneWrapperHelperProps = {
  value: DropzoneWrapperHelperFileIds;
  onChange: (value: DropzoneWrapperHelperFileIds) => void;
};

function DropzoneWrapperHelper({
  children,
  type,
  disabled,
  withFullscreenDropzone,
  withPasteFiles,
  className,
  value,
  onChange,
  dataParams,
  maxFiles,
  accept,
  preventMaxFilesOverload,
  onFilesAccepted,
  onFileCreated,
  onUploadProgress,
  onFileUploaded,
  onFileFailed,
  onFinishUpload,
  onDownloadProgress,
  removedFileIds
}: DropzoneWrapperHelperRequiredProps & DropzoneWrapperHelperProps) {
  const valueRef = useRef<DropzoneWrapperHelperFileIds>(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const ignoredFileIds = useRef(removedFileIds);
  useEffect(() => {
    ignoredFileIds.current = removedFileIds;
  }, [removedFileIds]);

  const handleFileUploaded =
    useCallback<S3MultipartDropzoneWrapperOnFileUploaded>(
      (id, uploadedId, updatedFile, file) => {
        onFileUploaded?.(id, uploadedId, updatedFile, file);

        if (size(ignoredFileIds.current) > 0) {
          const isRemovedFileId = find(
            ignoredFileIds.current,
            (ignoredFileId) => ignoredFileId === id
          );

          if (isRemovedFileId) {
            return;
          }
        }

        onChange([...valueRef.current, uploadedId]);
      },
      [onChange, onFileUploaded]
    );

  return (
    <S3MultipartDropzoneWrapper
      type={type}
      disabled={disabled}
      activeFilesCount={size(value)}
      withFullscreenDropzone={withFullscreenDropzone}
      withPasteFiles={withPasteFiles}
      className={className}
      onFilesAccepted={onFilesAccepted}
      onFileUploaded={handleFileUploaded}
      onFileFailed={onFileFailed}
      dataParams={dataParams}
      maxFiles={maxFiles}
      accept={accept}
      preventMaxFilesOverload={preventMaxFilesOverload}
      onFileCreated={onFileCreated}
      onUploadProgress={onUploadProgress}
      onFinishUpload={onFinishUpload}
      onDownloadProgress={onDownloadProgress}
    >
      {children}
    </S3MultipartDropzoneWrapper>
  );
}

export default DropzoneWrapperHelper;
