import React from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';
import {
  FetchMessagesCacheKey,
  MessageUUID,
  MessageVisibleForClient
} from '../../../../../../../messagesTypes';

import { MessageVisibleForClientButton } from '../../../../../../buttons/MessageVisibleForClientButton';

import { words } from '../../../../../../../../../locales/keys';

interface MessageMenuVFCButtonMessage {
  uuid: MessageUUID;
  visibleForClient: MessageVisibleForClient;
}

interface MessageMenuVFCButtonProps {
  message: MessageMenuVFCButtonMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageMenuVFCButton({
  message,
  messagesCacheKey
}: MessageMenuVFCButtonProps) {
  return (
    <MessageVisibleForClientButton
      className={
        message.visibleForClient
          ? 'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-blue-500 dark:text-blue-700 hover:text-blue-600 dark:hover:text-blue-500 focus:ring-offset-0'
          : 'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
      }
      uuid={message.uuid}
      cacheKey={messagesCacheKey}
      icon={
        message.visibleForClient
          ? IconsEnum.EYE_OUTLINE
          : IconsEnum.EYE_OFF_OUTLINE
      }
      tooltipI18nText={
        message.visibleForClient
          ? words.invisibleForClient
          : words.visibleForClient
      }
    />
  );
}

export default MessageMenuVFCButton;
