import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  CheckableMessagesProps,
  CheckedMessage
} from '../../../../../../../common/hooks/useMessagesCheckable';

type ItemMessagesListMessageCheckBoxFieldProps = {
  message: CheckedMessage;
} & CheckableMessagesProps;

function ItemMessagesListMessageCheckBoxField({
  message,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListMessageCheckBoxFieldProps) {
  const handleSelect = useCallback<() => void>(
    () => onSetCheckedMessage(message),
    [message, onSetCheckedMessage]
  );

  if (isEmpty(checkedMessages)) {
    return null;
  }

  return (
    <div
      className="flex items-center pr-2 pt-5"
      data-toggle-item="message-select"
    >
      <div className="flex items-center">
        <input
          id={message.uuid}
          type="checkbox"
          checked={!!checkedMessages[message.uuid]}
          data-batch-id="messages"
          className="basic-checkbox rounded-full"
          onChange={handleSelect}
        />
      </div>
    </div>
  );
}

export default ItemMessagesListMessageCheckBoxField;
