import React, { useState } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { Translate } from '../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../helpers/Icon';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { DateHelper } from '../../../../../../../helpers/DateHelper';

import { words } from '../../../../../../../locales/keys';

import { ARCHIVIZIER_TEAM_TIME_ZONE } from '../../../../../../../config';

function ItemCreateMessageArchivizerLocalTime() {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const kyivDateStrings = new Intl.DateTimeFormat(undefined, {
    timeZone: ARCHIVIZIER_TEAM_TIME_ZONE,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  }).formatToParts(new Date());

  const kyivTimeStringsToDate = kyivDateStrings.reduce(
    (acc, { type, value }) => {
      if (type === 'year') {
        acc.year = Number(value);
      }

      if (type === 'month') {
        acc.month = Number(value) - 1;
      }

      if (type === 'day') {
        acc.day = Number(value);
      }

      if (type === 'hour') {
        acc.hour = Number(value);
      }

      if (type === 'minute') {
        acc.minute = Number(value);
      }

      if (type === 'second') {
        acc.second = Number(value);
      }

      return acc;
    },
    {} as Record<
      'year' | 'month' | 'day' | 'hour' | 'minute' | 'second',
      number
    >
  );

  const kyivDate = new Date(
    kyivTimeStringsToDate.year,
    kyivTimeStringsToDate.month,
    kyivTimeStringsToDate.day,
    kyivTimeStringsToDate.hour,
    kyivTimeStringsToDate.minute,
    kyivTimeStringsToDate.second
  );

  return (
    <>
      <div
        className="max-w-full flex items-center justify-center gap-x-1"
        ref={setReferenceTooltipElement}
      >
        <Icon icon={IconsEnum.CLOCK} className="w-4 h-4 shrink-0" />
        <span>
          <DateHelper date={kyivDate} customFormat="HH:mm" />{' '}
          <Translate id={words.for} /> <Translate id={words.archivizerTeam} />
        </span>
      </div>

      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={TooltipPlacement.TOP}
        withArrow
      >
        <span className="text-sm">
          <DateHelper
            date={kyivDate}
            customFormat="HH:mm, EEEE, dd MMMM yyyy"
          />
        </span>
      </Tooltip>
    </>
  );
}

export default ItemCreateMessageArchivizerLocalTime;
