import {
  FileAttachmentDrawing,
  FileAttachmentReference,
  FileAttachmentTexture
} from '../../../../../main/fileAttachments/fileAttachmentsTypes';

import { DropzoneTabType } from '../../../DropzoneTabs';

interface FileAttachmentDropzoneTabFileAttachment {
  drawing: FileAttachmentDrawing;
  reference: FileAttachmentReference;
  texture: FileAttachmentTexture;
}

interface FileAttachmentDropzoneTabOptions {
  fileAttachment: FileAttachmentDropzoneTabFileAttachment;
}

function getFileAttachmentDropzoneTab({
  fileAttachment
}: FileAttachmentDropzoneTabOptions): DropzoneTabType {
  if (fileAttachment.drawing) {
    return 'drawing';
  }
  if (fileAttachment.reference) {
    return 'reference';
  }
  if (fileAttachment.texture) {
    return 'texture';
  }

  return 'general';
}

export default getFileAttachmentDropzoneTab;
