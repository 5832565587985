import React, { useCallback } from 'react';

import { PureTooltipIconButtonHelper } from '../../../../buttons/PureTooltipIconButtonHelper';

import { IconTooltipBooleanFieldButtonRequiredProps } from './TooltipIconBooleanFieldButton.types';
import { TooltipPlacement } from '../../../../tooltips/tooltipsConstants';

interface TooltipIconBooleanFieldButtonProps {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

function TooltipIconBooleanFieldButton({
  value,
  yesClassName,
  noClassName,
  yesIcon,
  noIcon,
  disabled,
  onChange,
  yesIconClassName,
  noIconClassName,
  noTooltipI18nText,
  yesTooltipI18nText
}: TooltipIconBooleanFieldButtonProps &
  IconTooltipBooleanFieldButtonRequiredProps) {
  const handleClick = useCallback<() => void>(
    () => onChange(!value),
    [value, onChange]
  );

  return (
    <PureTooltipIconButtonHelper
      className={value ? yesClassName : noClassName}
      disabled={disabled}
      icon={value ? yesIcon : noIcon}
      iconClassName={value ? yesIconClassName : noIconClassName}
      onClick={handleClick}
      tooltipI18nText={value ? yesTooltipI18nText : noTooltipI18nText}
      tooltipPlacement={TooltipPlacement.TOP}
    />
  );
}

export default TooltipIconBooleanFieldButton;
