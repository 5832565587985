import React, { useCallback, useState } from 'react';
import first from 'lodash/first';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { FetchMessagesCacheKey } from '../../../messagesTypes';
import { TaskID } from '../../../../tasks/tasksTypes';
import { ProjectID } from '../../../../projects/projectsTypes';

import { useToastNotification } from '../../../../../common/hooks/useToastNotification';
import { useCreateMessageFromAiProductsSearch } from '../../../hooks/useCreateMessageFromAiProductsSearch';

import {
  ModelingCategoriesSelect,
  ModelingCategoriesSelectCategory
} from '../../../../modelingCategories/helpers/ModelingCategoriesSelect';

import { DropzoneModalButton } from '../../../../../helpers/dropzone/DropzoneModalButton';
import { Translate } from '../../../../../helpers/Translate';
import { Loading } from '../../../../../helpers/Loading';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { MessagesPermissions } from '../../../messagesConstants';

import { words, aiSearchKeys } from '../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface CreateMessageFromAiProductsSearchModalButtonProps {
  taskId?: TaskID;
  projectId?: ProjectID;
  cacheKeys?: FetchMessagesCacheKey[];
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
}

const initialValueFileAttachments = [];

function CreateMessageFromAiProductsSearchModalButton({
  taskId,
  projectId,
  cacheKeys,
  disabled,
  icon,
  iconClassName,
  className,
  i18nText,
  tooltipI18nText
}: CreateMessageFromAiProductsSearchModalButtonProps) {
  const {
    createMessageFromAiProductsSearch,
    createMessageFromAiProductsSearchErrorMessage,
    createMessageFromAiProductsSearchLoading
  } = useCreateMessageFromAiProductsSearch<unknown>({
    cacheKeys
  });

  const [selectedCategory, setSelectedCategory] =
    useState<ModelingCategoriesSelectCategory>(null);

  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: words.success
  });

  const handleSubmit = useCallback<(value: string[]) => Promise<unknown>>(
    (value) => {
      const fileAttachmentId = first(value);
      return createMessageFromAiProductsSearch({
        taskId,
        projectId,
        fileAttachmentId,
        productCategoryId: selectedCategory?.productCategory?.id
      }).then(() => showToastI18nNotification());
    },
    [
      taskId,
      projectId,
      selectedCategory,
      showToastI18nNotification,
      createMessageFromAiProductsSearch
    ]
  );

  return (
    <DropzoneModalButton
      type="fileAttachments"
      value={initialValueFileAttachments}
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={TooltipPlacement.TOP}
      i18nTitle={aiSearchKeys.aiPoweredSearch}
      i18nSubmitText={aiSearchKeys.search}
      onSubmit={handleSubmit}
      disabled={disabled}
      submitDisabled={createMessageFromAiProductsSearchLoading}
      submitErrorMessage={createMessageFromAiProductsSearchErrorMessage}
      resetOnClose
      withoutTabs
      withoutDropzoneArea={createMessageFromAiProductsSearchLoading}
      beforeDropzone={
        <div>
          {createMessageFromAiProductsSearchLoading ? (
            <div className="p-4">
              <Loading addClass="w-full" />
            </div>
          ) : (
            <div className="text-sm mb-4">
              <Translate id={aiSearchKeys.pleaseUploadASinglePhoto} />
            </div>
          )}
          <CheckPermissions
            action={
              MessagesPermissions.CHANGE_MESSAGE_CREATE_FORM_AI_DETECT_SEARCH_MODAL_BUTTON_CATEGORY
            }
          >
            <ModelingCategoriesSelect
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </CheckPermissions>
        </div>
      }
      maxFiles={1}
      preventMaxFilesOverload
    />
  );
}

export default CreateMessageFromAiProductsSearchModalButton;
