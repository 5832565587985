import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import find from 'lodash/find';

import { ItemMessagesListMessageRepliedMessageTodoItemsItem } from './ItemMessagesListMessageRepliedMessageTodoItems.types';

import { Checkbox } from '../../../../../../../../../helpers/Checkbox';
import { MarkdownHelper } from '../../../../../../../../../helpers/MarkdownHelper';

import { sortTodoItemsByOrder } from '../../../../../../../../todoItems/utils/sortTodoItemsByOrder';

import {
  TODO_ITEM_NAME_TITLE,
  TODO_ITEM_NAME_WITHOUT_CHECKBOX
} from '../../../../../../../../todoItems/todoItemsConstants';

const regexTodoItemNameAsTitle = new RegExp(`^\\${TODO_ITEM_NAME_TITLE}`);
const regexTodoItemNameWithoutCheckbox = new RegExp(
  `^${TODO_ITEM_NAME_WITHOUT_CHECKBOX}`
);

interface ItemMessagesListMessageRepliedMessageTodoItemsProps {
  todoItems: ItemMessagesListMessageRepliedMessageTodoItemsItem[];
}

function ItemMessagesListMessageRepliedMessageTodoItems({
  todoItems
}: ItemMessagesListMessageRepliedMessageTodoItemsProps) {
  const todoListItems = useMemo(
    () =>
      filter(
        sortTodoItemsByOrder(todoItems),
        (item) => !regexTodoItemNameAsTitle.test(item.name)
      ),
    [todoItems]
  );

  const todoTitle = useMemo(() => {
    const titleTodoItem = find(todoItems, (item) =>
      regexTodoItemNameAsTitle.test(item.name)
    );

    return titleTodoItem?.name.replace(regexTodoItemNameAsTitle, '');
  }, [todoItems]);

  if (isEmpty(todoItems)) {
    return null;
  }

  return (
    <div className="px-1">
      {todoTitle && (
        <div className="mb-2">
          <div className="font-semibold px-8 py-1 text-base">{todoTitle}</div>
        </div>
      )}

      <div className="space-y-3">
        {todoListItems.map(
          (todoItem, index) =>
            index < 3 && (
              <div key={todoItem.id} className="flex gap-1 items-start py-1">
                {!regexTodoItemNameWithoutCheckbox.test(todoItem.name) && (
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <Checkbox checked={todoItem.done} disabled />
                    </div>
                  </div>
                )}

                <MarkdownHelper
                  source={todoItem.name.replace(
                    regexTodoItemNameWithoutCheckbox,
                    ''
                  )}
                  className={
                    todoItem.done
                      ? 'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug dark:markdown-invert line-through pl-2 text-sm'
                      : 'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug dark:markdown-invert pl-2 text-sm'
                  }
                  fromMessage
                  linkTarget="_blank"
                />
              </div>
            )
        )}

        {todoListItems.length > 3 && <div>...</div>}
      </div>
    </div>
  );
}

export default ItemMessagesListMessageRepliedMessageTodoItems;
