import { gql } from 'graphql-request';
import {
  ProductCreatedAt,
  ProductID,
  ProductName,
  ProductNanoID,
  ProductUUID,
  ProductUserFullName,
  ProductUserNanoID
} from '../productsTypes';

export interface FetchProductInfoQueryResponse {
  id: ProductID;
  uuid: ProductUUID;
  nanoId: ProductNanoID;
  name: ProductName;
  createdAt: ProductCreatedAt;
  user: {
    nanoId: ProductUserNanoID;
    fullName: ProductUserFullName;
  };
}

export const FETCH_PRODUCT_INFO_QUERY = gql`
  query ProductInfo($uuid: ID!) {
    product(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      createdAt
      user {
        nanoId
        fullName
      }
    }
  }
`;
