import * as yup from 'yup';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { formsErrors } from '../../../../../../../locales/keys';

import { MessageFields } from '../../../../../messagesTypes';

export const itemCreateMessageFormValidationSchema = yup.object().shape(
  {
    [MessageFields.BODY]: yup.string().when([MessageFields.LEXICAL], {
      is: (value: object) =>
        isEmpty(get(value, 'text')) && isEmpty(get(value, 'mentionIds')),
      then: yup.string().when([MessageFields.FILE_ATTACHMENT_IDS], {
        is: (value: string[]) => !value || !value?.length,
        then: yup.string().when([MessageFields.ATTACHED_FILE_ATTACHMENT_IDS], {
          is: (value: string[]) => !value || !value?.length,
          then: yup.string().when([MessageFields.SELECTED_PRODUCT_IDS], {
            is: (value: string[]) => !value || !value?.length,
            then: yup.string().when([MessageFields.SELECTED_LIFESTYLE_IDS], {
              is: (value: string[]) => !value || !value?.length,
              then: yup.string().when([MessageFields.SELECTED_MATERIAL_IDS], {
                is: (value: string[]) => !value || !value?.length,
                then: yup.string().when([MessageFields.REPLIED_MESSAGE_ID], {
                  is: (value: string) => !value,
                  then: yup.string().when([MessageFields.COLORS], {
                    is: (value: string[]) => !value || !value?.length,
                    then: yup.string().required(formsErrors.message.required),
                    otherwise: yup.string()
                  })
                })
              })
            })
          })
        })
      })
    }),
    [MessageFields.LEXICAL]: yup.object().when([MessageFields.BODY], {
      is: (value: string) => !value || !value?.length,
      then: yup.object().when([MessageFields.FILE_ATTACHMENT_IDS], {
        is: (value: string[]) => !value || !value?.length,
        then: yup.object().when([MessageFields.ATTACHED_FILE_ATTACHMENT_IDS], {
          is: (value: string[]) => !value || !value?.length,
          then: yup.object().when([MessageFields.SELECTED_PRODUCT_IDS], {
            is: (value: string[]) => !value || !value?.length,
            then: yup.object().when([MessageFields.SELECTED_LIFESTYLE_IDS], {
              is: (value: string[]) => !value || !value?.length,
              then: yup.object().when([MessageFields.SELECTED_MATERIAL_IDS], {
                is: (value: string[]) => !value || !value?.length,
                then: yup.object().when([MessageFields.REPLIED_MESSAGE_ID], {
                  is: (value: string) => !value,
                  then: yup.object().when([MessageFields.COLORS], {
                    is: (value: string[]) => !value || !value?.length,
                    then: yup.object().required(formsErrors.message.required),
                    otherwise: yup.object()
                  })
                })
              })
            })
          })
        })
      })
    })
  },
  [['body', 'lexical']]
);
