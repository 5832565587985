import isEmpty from 'lodash/isEmpty';

import { MessageMessageTypes } from '../../../../../messagesTypes';
import { ItemMessagesListMessageItem } from '../../../ItemMessagesListMessage';

function defineIsAuthorSame(
  firstMessage: ItemMessagesListMessageItem,
  secondMessage: ItemMessagesListMessageItem
): boolean {
  const isEmptyForwardSecondMessage =
    secondMessage?.forwardedMessage && !secondMessage?.body;

  const isEmptyForwardFirstMessage =
    firstMessage.forwardedMessage && !firstMessage.body;

  const isWhiteboardSecondMessage =
    secondMessage?.whiteboardKeyId && !secondMessage?.threadKeyId;

  const isWhiteboardFirstMessage =
    firstMessage.whiteboardKeyId && !firstMessage.threadKeyId;

  const isTrackTimeSecondMessage =
    secondMessage?.messageType === MessageMessageTypes.TRACK_TIME ||
    secondMessage?.hours > 0 ||
    secondMessage?.minutes > 0;

  const isTrackTimeFirstMessage =
    firstMessage.messageType === MessageMessageTypes.TRACK_TIME ||
    firstMessage.hours > 0 ||
    firstMessage.minutes > 0;

  const isSameAuthor =
    secondMessage?.user?.uuid === firstMessage.user?.uuid &&
    isEmpty(firstMessage.fileAttachments) &&
    isEmpty(firstMessage.selectedProducts) &&
    isEmpty(firstMessage.selectedLifestyles) &&
    isEmpty(firstMessage.selectedMaterials) &&
    !isTrackTimeFirstMessage &&
    !isWhiteboardFirstMessage &&
    !isEmptyForwardFirstMessage &&
    isEmpty(secondMessage?.fileAttachments) &&
    isEmpty(secondMessage?.selectedProducts) &&
    isEmpty(secondMessage?.selectedLifestyles) &&
    !isTrackTimeSecondMessage &&
    !isWhiteboardSecondMessage &&
    !isEmptyForwardSecondMessage;

  return isSameAuthor;
}

export default defineIsAuthorSame;
