import {
  FetchSelectedLifestylesFilters,
  FetchSelectedLifestylesSort,
  FetchSelectedLifestylesLimit,
  FetchSelectedLifestylesGqlQuery,
  FetchSelectedLifestylesCacheKey
} from '../../selectedLifestylesTypes';

import {
  INITIAL_SELECTED_LIFESTYLES_FILTERS,
  INITIAL_SELECTED_LIFESTYLES_LIMIT,
  INITIAL_SELECTED_LIFESTYLES_SORT
} from '../../selectedLifestylesConstants';

import {
  ReadInfiniteIndexQueryBaseNodeType,
  useReadInfiniteIndexQuery
} from '../../../common/hooks/base/reactQuery/useReadInfiniteIndexQuery';

interface SelectedLifestylesOptions {
  cacheKey: FetchSelectedLifestylesCacheKey;
  query: FetchSelectedLifestylesGqlQuery;
  initialFilters?: FetchSelectedLifestylesFilters;
  initialSort?: FetchSelectedLifestylesSort;
  initialLimit?: FetchSelectedLifestylesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    cacheTime?: number;
  };
}

const scope = 'selectedLifestyles';

function useSelectedLifestyles<
  SelectedLifestyleItemType extends ReadInfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialFilters = INITIAL_SELECTED_LIFESTYLES_FILTERS,
  initialSort = INITIAL_SELECTED_LIFESTYLES_SORT,
  initialLimit = INITIAL_SELECTED_LIFESTYLES_LIMIT,
  options = {}
}: SelectedLifestylesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    changeItemsFilters,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems
  } = useReadInfiniteIndexQuery<SelectedLifestyleItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    selectedLifestylesData: data,
    selectedLifestyles: items,
    selectedLifestylesError: itemsError,
    selectedLifestylesTotalCount: itemsTotalCount,
    selectedLifestylesFetched: isFetched,
    selectedLifestylesLoading: isLoading,
    selectedLifestylesFetchingNextPage: isFetchingNextPage,
    selectedLifestylesIsPlaceholderData: isPlaceholderData,
    selectedLifestylesFilters: currentFilters,
    selectedLifestylesSort: currentSort,
    selectedLifestylesPage: currentPage,
    selectedLifestylesLimit: currentLimit,
    hasNextSelectedLifestylesPage: hasNextPage,
    updateSelectedLifestyleCache: updateItemCache,
    loadMoreSelectedLifestyles: loadMoreItems,
    filterSelectedLifestyles: filterItems,
    clearSelectedLifestylesFilters: clearItemsFilters,
    sortSelectedLifestyles: sortItems,
    limitSelectedLifestyles: limitItems,
    changeSelectedLifestylesFilters: changeItemsFilters
  };
}

export default useSelectedLifestyles;
