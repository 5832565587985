import React from 'react';

import { ItemMessagesListMessageRepliedMessageFileFileAttachment } from './ItemMessagesListMessageRepliedMessageFile.types';

import { Icon } from '../../../../../../../../../helpers/Icon';

import { getFileIcon } from '../../../../../../../../../utils/getFileIcon';

interface ItemMessagesListMessageRepliedMessageFileProps {
  fileAttachment: ItemMessagesListMessageRepliedMessageFileFileAttachment;
  hiddenFilesCount?: number;
}

function ItemMessagesListMessageRepliedMessageFile({
  fileAttachment,
  hiddenFilesCount
}: ItemMessagesListMessageRepliedMessageFileProps) {
  return (
    <div className="min-h-14 relative rounded bg-gray-100 dark:bg-gray-850 group/item max-w-48 pl-2  py-1.5 items-center flex">
      <div className="absolute top-0 left-0 h-full flex items-center px-2">
        <Icon icon={getFileIcon(fileAttachment.file)} className="h-6 w-6" />
      </div>

      <span className="line-clamp-3 text-sm break-words leading-tight pl-8 rounded pr-3.5">
        {fileAttachment.name}
      </span>

      {hiddenFilesCount && (
        <div className="absolute inset-0 flex items-center justify-center rounded object-cover bg-opacity-80 dark:bg-opacity-80 bg-white dark:bg-gray-900 text-lg font-medium">
          +{hiddenFilesCount}
        </div>
      )}
    </div>
  );
}

export default ItemMessagesListMessageRepliedMessageFile;
