import {
  FetchMessageGqlQuery,
  MessageID,
  MessageUUID
} from '../../messagesTypes';

import { FetchMessageCacheKey } from '../../messagesTypes';

import { useFinShowQuery } from '../../../common/hooks/base/reactQuery/useFinShowQuery';

interface MessageResponse<ItemType> {
  message: ItemType | null;
}

interface MessageOptions {
  cacheKey: FetchMessageCacheKey;
  query: FetchMessageGqlQuery;
  uuid: MessageUUID | MessageID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

interface MessageItem {
  uuid: MessageUUID;
}

const itemKey = 'message';

function useFinMessage<ItemType extends MessageItem>({
  cacheKey,
  query,
  uuid,
  options = {}
}: MessageOptions) {
  const {
    item,
    itemErrorData,
    itemError,
    itemFetched,
    itemFetching,
    itemLoading,
    itemIsPlaceholderData
  } = useFinShowQuery<MessageResponse<ItemType>, ItemType>({
    cacheKey,
    itemKey,
    query,
    uuid,
    options
  });

  return {
    message: item,
    messageErrorData: itemErrorData,
    messageError: itemError,
    messageFetched: itemFetched,
    messageFetching: itemFetching,
    messageLoading: itemLoading,
    messageIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useFinMessage;
