import { ClassName } from '../../../../../../../types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { ItemMessagesListMessageImageVisibleForClient } from '../ItemMessagesListMessageImageVisibleForClient';
import {
  ItemMessagesListMessageImageSelectedMaterialAttachment,
  ItemMessagesListMessageImageHandleOpenLightbox
} from '../ItemMessagesListMessageImage';
import { MessagesListMessageImageMaterialButtons } from '../../../MessagesListMessage/components/MessagesListMessageImageMaterial/components/MessagesListMessageImageMaterialButtons';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { materialsKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageImageMaterialProps {
  messagesCacheKey: FetchMessagesCacheKey;
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: ItemMessagesListMessageImageSelectedMaterialAttachment;
  isVisibleForClient: boolean;
  handleOpenLightbox: ItemMessagesListMessageImageHandleOpenLightbox;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageImageMaterial({
  messagesCacheKey,
  linkClassName,
  imageClassName,
  attachment,
  isVisibleForClient,
  handleOpenLightbox,
  tooltipSingleton
}: ItemMessagesListMessageImageMaterialProps) {
  return (
    <div className="group/item relative rounded-md border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800">
      <LinkHelper
        className={linkClassName}
        href={attachment.material.image.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          tooltipAlt
          tooltipSingleton={tooltipSingleton}
          className={imageClassName}
          src={attachment.material.image.file}
          alt={attachment.material.image.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
        />
      </LinkHelper>
      <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1">
        {isVisibleForClient && <ItemMessagesListMessageImageVisibleForClient />}
        <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-teal-600">
          <Translate id={materialsKeys.plural} />
        </span>
      </div>
      <MessagesListMessageImageMaterialButtons
        attachment={attachment}
        messagesCacheKey={messagesCacheKey}
      />
    </div>
  );
}

export default ItemMessagesListMessageImageMaterial;
