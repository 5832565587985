import { ClassName } from '../../../../../../../types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { MessagesListMessageImageLifestyleButtons } from '../../../MessagesListMessage/components/MessagesListMessageImageLifestyle/components/MessagesListMessageImageLifestyleButtons';
import { ItemMessagesListMessageImageVisibleForClient } from '../ItemMessagesListMessageImageVisibleForClient';
import {
  ItemMessagesListMessageImageSelectedLifestyleAttachment,
  ItemMessagesListMessageImageHandleOpenLightbox
} from '../ItemMessagesListMessageImage';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';
import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { IconWithTooltip } from '../../../../../../../helpers/IconWithTooltip';

import { LifestylesPermissions } from '../../../../../../lifestyles/lifestylesConstants';

import {
  librariesKeys,
  lifestylesKeys
} from '../../../../../../../locales/keys';

interface ItemMessagesListMessageImageLifestyleProps {
  messagesCacheKey: FetchMessagesCacheKey;
  linkClassName: ClassName;
  imageClassName: ClassName;
  attachment: ItemMessagesListMessageImageSelectedLifestyleAttachment;
  isVisibleForClient: boolean;
  handleOpenLightbox: ItemMessagesListMessageImageHandleOpenLightbox;
}

function ItemMessagesListMessageImageLifestyle({
  messagesCacheKey,
  linkClassName,
  imageClassName,
  attachment,
  isVisibleForClient,
  handleOpenLightbox
}: ItemMessagesListMessageImageLifestyleProps) {
  return (
    <div className="group/item relative rounded-md border border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800">
      <LinkHelper
        className={linkClassName}
        href={attachment.lifestyle.image.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          className={imageClassName}
          src={attachment.lifestyle.image.file}
          alt={attachment.lifestyle.name}
          version={FileAttachmentItemImageVersions.BigThumb538x435}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />

        <CheckPermissions
          action={LifestylesPermissions.READ_LIFESTYLE_PREMIUM_CONTENT_AT}
        >
          {attachment.lifestyle.premiumContentAt ? (
            <div className="absolute z-0 inset-0 pointer-events-none flex flex-col justify-between">
              <div className="flex gap-1 pointer-events-auto p-2">
                <div className="p-1 rounded bg-gray-900/75 text-yellow-500">
                  <IconWithTooltip
                    icon={IconsEnum.CROWN_SOLID}
                    iconClassName="h-5 w-5"
                    i18nText={librariesKeys.premium}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </CheckPermissions>
      </LinkHelper>
      <div className="absolute inset-0 flex items-end justify-start p-2 pointer-events-none space-x-1">
        {isVisibleForClient && <ItemMessagesListMessageImageVisibleForClient />}
        <span className="px-1.5 py-0.5 text-white rounded shadow text-xs bg-pink-600">
          <Translate id={lifestylesKeys.plural} />
        </span>
      </div>
      <MessagesListMessageImageLifestyleButtons
        attachment={attachment}
        messagesCacheKey={messagesCacheKey}
      />
    </div>
  );
}

export default ItemMessagesListMessageImageLifestyle;
