import { useCallback, useEffect, useState } from 'react';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

import {
  ItemMessagesListMessages,
  ItemMessagesListMessagesFetched
} from '../../ItemMessagesList.types';

import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';

import { ItemMessagesListMessageItem } from '../../../ItemMessagesListMessage';

interface ItemMessagesListOptions {
  messages: ItemMessagesListMessages;
  messagesFetched: ItemMessagesListMessagesFetched;
  sendingMessages?: ItemMessagesListMessages;
  hasPreviousMessagesPage?: boolean;
  goToLastMessage?: () => void;
}

function useItemMessagesList({
  messages,
  messagesFetched,
  sendingMessages,
  hasPreviousMessagesPage,
  goToLastMessage
}: ItemMessagesListOptions) {
  const [isDateShown, setIsDateShown] = useState<boolean>(false);

  const toggleShowDate = useCallback<() => void>(() => {
    setIsDateShown((prevVal) => !prevVal);
  }, []);

  const sortedMessages = concat(
    sortBy<ItemMessagesListMessageItem>(messages, (message) =>
      parseInt(message.id as string, 10)
    ),
    sendingMessages
  );

  const lastMessageUuid =
    last<ItemMessagesListMessageItem>(sortedMessages)?.uuid;

  const firsMessageUuid =
    first<ItemMessagesListMessageItem>(sortedMessages)?.uuid;

  const prevFirstMessageUuid = usePreviousValue(firsMessageUuid);

  const [messagesScrollElement, setMessagesScrollElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    if (
      messagesScrollElement &&
      prevFirstMessageUuid &&
      prevFirstMessageUuid !== firsMessageUuid
    ) {
      const scrollToLastMessage = () => {
        const item = document.getElementById(`message-${prevFirstMessageUuid}`);

        const itemPosition = item?.getBoundingClientRect();
        const scrollElementPosition =
          messagesScrollElement.getBoundingClientRect();

        if (item && itemPosition.top > scrollElementPosition.top) {
          messagesScrollElement.scrollTo({
            top: itemPosition.top - scrollElementPosition.top
          });
        }
      };

      if (messagesFetched) {
        requestAnimationFrame(scrollToLastMessage);
      }
    }
  }, [
    firsMessageUuid,
    messagesFetched,
    messagesScrollElement,
    prevFirstMessageUuid
  ]);

  useEffect(() => {
    if (messagesScrollElement && !hasPreviousMessagesPage) {
      const scrollToLastMessage = () => {
        const item = document.getElementById(`message-${lastMessageUuid}`);

        const itemPosition = item?.getBoundingClientRect();
        const scrollElementPosition =
          messagesScrollElement.getBoundingClientRect();

        if (item && itemPosition.bottom > scrollElementPosition.bottom) {
          messagesScrollElement.scrollTo({
            top: messagesScrollElement.scrollHeight
          });
        }
      };

      if (messagesFetched) {
        requestAnimationFrame(scrollToLastMessage);
      }
    }
  }, [
    hasPreviousMessagesPage,
    lastMessageUuid,
    messagesFetched,
    messagesScrollElement
  ]);

  useEffect(() => {
    if (
      hasPreviousMessagesPage &&
      goToLastMessage &&
      !isEmpty(sendingMessages)
    ) {
      goToLastMessage();
    }
  }, [goToLastMessage, hasPreviousMessagesPage, sendingMessages]);

  return {
    isDateShown,
    sortedMessages,
    messagesScrollElement,
    setMessagesScrollElement,
    toggleShowDate
  };
}

export default useItemMessagesList;
