import compact from 'lodash/compact';

import { useCreateQuery } from '../../../common/hooks/base/reactQuery/useCreateQuery';

import {
  CreateChatGptCompletionCacheKeys,
  CreateChatGptCompletionGqlError,
  CreateChatGptCompletionGqlQuery,
  CreateChatGptCompletionGqlStatus
} from '../../chatGptCompletionsTypes/createChatGptCompletionTypes';

import {
  ChatGptCompletionMessageContent,
  ChatGptCompletionMessageRole,
  ChatGptCompletionMessages,
  ChatGptCompletionModel,
  ChatGptCompletionUrl,
  ChatGptCompletionUUID
} from '../../chatGptCompletionsTypes/chatGptCompletionsFieldsTypes';

import { generateNanoId } from '../../../../utils/generateNanoId';

import { chatGptCompletionApiUrl } from '../../chatGptCompletionsConstants';

import { CHAT_GPT_COMPLETIONS_MODEL } from '../../../../config';

export type ChatGptCompletionMessageType = {
  role: ChatGptCompletionMessageRole;
  content: ChatGptCompletionMessageContent;
};

export type ChatGptCompletionMessagesType = ChatGptCompletionMessageType[];

export type CreateChatGptCompletionInputType = {
  url: ChatGptCompletionUrl;
  model: ChatGptCompletionModel;
  messages: ChatGptCompletionMessagesType;
};

export interface CreateChatGptCompletionInput {
  clientMutationId?: string;
  url: ChatGptCompletionUrl;
  model: ChatGptCompletionModel;
  messages: ChatGptCompletionMessages;
}

interface CreateChatGptCompletionResponse<CreateChatGptCompletionRecordType> {
  createChatGptCompletion: {
    status: CreateChatGptCompletionGqlStatus;
    recordUuid: ChatGptCompletionUUID;
    record: CreateChatGptCompletionRecordType;
    errors: CreateChatGptCompletionErrors;
  };
}

interface CreateChatGptCompletionErrors {
  fullMessages: CreateChatGptCompletionGqlError;
}

interface CreateChatGptCompletionOptions {
  query: CreateChatGptCompletionGqlQuery;
  cacheKeys: CreateChatGptCompletionCacheKeys;
}

const action = 'createChatGptCompletion';

function useCreateChatGptCompletion<CreateChatGptCompletionRecordType>({
  cacheKeys,
  query
}: CreateChatGptCompletionOptions) {
  const {
    createQueryData,
    createQuery,
    createQueryReset,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading
  } = useCreateQuery<
    CreateChatGptCompletionInput,
    CreateChatGptCompletionResponse<CreateChatGptCompletionRecordType>,
    CreateChatGptCompletionErrors,
    CreateChatGptCompletionRecordType
  >({
    action,
    cacheKeys,
    query
  });

  return {
    createChatGptCompletionData: createQueryData,
    createChatGptCompletionError: createQueryError,
    createChatGptCompletionLoading: createQueryLoading,
    createChatGptCompletionErrorMessage: createQueryErrorMessage,
    createChatGptCompletion: async (input: CreateChatGptCompletionInputType) =>
      createQuery({
        ...input,
        messages: JSON.parse(JSON.stringify(input?.messages))
      }),
    createChatGptCompletionReset: createQueryReset,
    handleCreateChatGptCompletion: async (
      userContent: ChatGptCompletionMessageContent,
      systemContent: ChatGptCompletionMessageContent = null
    ) => {
      const clientMutationId = generateNanoId();

      return createQuery({
        url: chatGptCompletionApiUrl,
        model: CHAT_GPT_COMPLETIONS_MODEL,
        clientMutationId,
        messages: JSON.parse(
          JSON.stringify(
            compact([
              systemContent
                ? {
                    role: 'system',
                    content: systemContent
                  }
                : null,
              {
                role: 'user',
                content: userContent
              }
            ])
          )
        )
      });
    }
  };
}

export default useCreateChatGptCompletion;
