import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  ModelBrand,
  FetchItemGqlQuery,
  FetchItemCacheKey,
  NanoID
} from '../../../types';

export type ChatGptCompletionID = ModelBrand<ID, 'ChatGptCompletionID'>;
export type ChatGptCompletionUUID = ModelBrand<UUID, 'ChatGptCompletionUUID'>;
export type ChatGptCompletionNanoID = ModelBrand<
  NanoID,
  'ChatGptCompletionNanoID'
>;
export type ChatGptCompletionUrl = string;
export type ChatGptCompletionModel = string;
export type ChatGptCompletionMessages = JSON;
export type ChatGptCompletionResponseBody = JSON;
export type ChatGptCompletionMessageRole = string;

export const enum ChatGptCompletionMessageContentTypeEnum {
  TEXT = 'text',
  IMAGE_URL = 'image_url'
}

export type ChatGptCompletionMessageContentElementType = {
  type: ChatGptCompletionMessageContentTypeEnum;
  image_url?: {
    url: string;
  };
  text?: string;
};

export type ChatGptCompletionMessageContentType =
  | ChatGptCompletionMessageContentElementType[]
  | string;

export type ChatGptCompletionMessageContent =
  ChatGptCompletionMessageContentType;

export type ChatGptCompletionCreatedAt = CreatedAt;
export type ChatGptCompletionUpdatedAt = UpdatedAt;

export type FetchChatGptCompletionGqlQuery = FetchItemGqlQuery;
export type FetchChatGptCompletionCacheKey = FetchItemCacheKey;
