import React, { useState } from 'react';

import { TeamNanoID } from '../../../../../../teams/teamsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FETCH_TEAM_TIME_ZONE_QUERY,
  FetchTeamTimeZoneQueryResponse
} from '../../../../../../teams/queries/fetchTeamTimeZone.query';

import { useFinTeam } from '../../../../../../teams/hooks/useFinTeam';

import { Translate } from '../../../../../../../helpers/Translate';
import { DateHelper } from '../../../../../../../helpers/DateHelper';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';
import { Icon } from '../../../../../../../helpers/Icon';
import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { TeamCache } from '../../../../../../teams/TeamCache';
import { words } from '../../../../../../../locales/keys';

import { getTeamLocalTime } from '../../../../../../teams/utils/getTeamLocalTime';

interface ItemCreateMessageClientLocalTimeProps {
  teamNanoId: TeamNanoID;
}

function ItemCreateMessageClientLocalTime({
  teamNanoId
}: ItemCreateMessageClientLocalTimeProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const { team, teamFetched, teamError } =
    useFinTeam<FetchTeamTimeZoneQueryResponse>({
      cacheKey: TeamCache.showCompanyTimeZoneCacheKey(),
      query: FETCH_TEAM_TIME_ZONE_QUERY,
      uuid: teamNanoId,
      options: {
        enabled: !!teamNanoId,
        enabledPlaceholder: !!teamNanoId
      }
    });

  if ((!team && !teamFetched) || !team?.timeZone) {
    return null;
  }

  const clientLocalTime = getTeamLocalTime(team?.timeZone);

  return (
    <>
      <div
        className="max-w-full flex items-center justify-center gap-x-1"
        ref={setReferenceTooltipElement}
      >
        <Icon icon={IconsEnum.CLOCK} className="w-4 h-4 shrink-0" />
        <span>
          <Translate id={words.its} />{' '}
          <DateHelper date={clientLocalTime} customFormat="HH:mm" />{' '}
          <Translate id={words.for} /> {team?.name}
        </span>
        <AlertMessage message={teamError} />
      </div>
      <Tooltip
        referenceElement={referenceTooltipElement}
        placement={TooltipPlacement.TOP}
        withArrow
      >
        <span className="text-sm">
          <DateHelper
            date={clientLocalTime}
            customFormat="HH:mm, EEEE, dd MMMM yyyy"
          />
        </span>
      </Tooltip>
    </>
  );
}

export default ItemCreateMessageClientLocalTime;
