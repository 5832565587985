import { useCallback, useEffect, useState } from 'react';
import some from 'lodash/some';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages,
  ItemMessagesListMessages,
  ItemMessagesListMessagesFetched
} from '../..';
import { MessageID, MessageUUID } from '../../../../../messagesTypes';

interface ItemMessagesListOptions {
  messages: ItemMessagesListMessages;
  messagesFetched: ItemMessagesListMessagesFetched;
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
}

function useJumpToMessage({
  messages,
  messagesFetched,
  filterMessages,
  messagesFilters
}: ItemMessagesListOptions) {
  const [goToMessage, setGoToMessage] = useState<MessageUUID>();

  const scrollToMessage = useCallback<(messageUuid: MessageUUID) => void>(
    (messageUuid) => {
      const item = document.getElementById(`message-${messageUuid}`);
      const messagesScrollElement = document.getElementById('message-thread');

      const itemPosition = item?.getBoundingClientRect();
      const scrollElementPosition =
        messagesScrollElement?.getBoundingClientRect();

      if (item && scrollElementPosition) {
        const offset = 100;
        const scrollElementTop = scrollElementPosition.top + offset;
        const scrollElementBottom = scrollElementPosition.bottom - offset;
        const itemTop = itemPosition.top;
        const itemBottom = itemPosition.bottom;

        if (itemTop < scrollElementTop) {
          messagesScrollElement?.scrollTo({
            behavior: 'smooth',
            top: messagesScrollElement.scrollTop + itemTop - scrollElementTop
          });
        }

        if (itemBottom > scrollElementBottom) {
          messagesScrollElement?.scrollTo({
            behavior: 'smooth',
            top:
              messagesScrollElement.scrollTop + itemBottom - scrollElementBottom
          });
        }
      }
    },
    []
  );

  const jumpToMessage = useCallback<
    (message: { id: MessageID; uuid: MessageUUID }) => void
  >(
    (message) => {
      if (some(messages, (m) => m.uuid === message.uuid)) {
        return scrollToMessage(message.uuid);
      }

      setGoToMessage(message.uuid);

      filterMessages({
        ...messagesFilters,
        id: {
          gte: message.id
        }
      });
    },
    [filterMessages, messages, messagesFilters, scrollToMessage]
  );

  const jumpToLastMessage = useCallback<
    (message: { id: MessageID; uuid: MessageUUID }) => void
  >(
    (message) => {
      if (some(messages, (m) => m.uuid === message.uuid)) {
        return scrollToMessage(message.uuid);
      }

      setGoToMessage(message.uuid);

      filterMessages({
        ...messagesFilters,
        id: undefined
      });
    },
    [filterMessages, messages, messagesFilters, scrollToMessage]
  );

  useEffect(() => {
    if (messages && messagesFetched && goToMessage) {
      const item = document.getElementById(`message-${goToMessage}`);

      if (some(messages, (m) => m.uuid === goToMessage) && item) {
        requestAnimationFrame(() => scrollToMessage(goToMessage));

        setGoToMessage(undefined);
      }
    }
  }, [goToMessage, messages, messagesFetched, scrollToMessage]);

  return {
    jumpToMessage,
    jumpToLastMessage
  };
}

export default useJumpToMessage;
