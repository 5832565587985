import { useState, useCallback } from 'react';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';

import { MessageID } from '../../../../../messagesTypes';

import {
  ItemCreateMessageFormData,
  ItemCreateMessageFormChange
} from '../useItemCreateMessageForm';
import { useReactQueryState } from '../../../../../../common/hooks/base/reactQuery/useReactQueryState';

import { generateNanoId } from '../../../../../../../utils/generateNanoId';

import { MessageCache } from '../../../../../MessageCache';

const CREATE_MESSAGE_STATE_FIELDS = [
  'lexical',
  'body',
  'visibleForClient',
  'fileAttachmentIds',
  'attachedFileAttachmentIds',
  'selectedProductIds',
  'selectedLifestyleIds',
  'selectedMaterialIds',
  'colors',
  'repliedMessageId'
];

export const enum ItemCreateMessageFormStateScope {
  TASK_OR_PROJECT = 'task-or-project',
  WHITEBOARD_COMMENT = 'whiteboard-comment'
}

interface ItemCreateMessageFormStateOptions {
  scope: ItemCreateMessageFormStateScope;
}

function useItemCreateMessageFormState({
  scope
}: ItemCreateMessageFormStateOptions) {
  const { value, setValue } = useReactQueryState<
    Partial<ItemCreateMessageFormData>
  >(MessageCache.itemCreateMessageFormState(scope));

  const [createMessageStateKey, setCreateMessageStateKey] = useState<string>(
    () => generateNanoId()
  );

  const handleCreateMessageFormStateChange =
    useCallback<ItemCreateMessageFormChange>(
      ({ data }) => {
        setValue(
          data &&
            pickBy(
              pick(data, CREATE_MESSAGE_STATE_FIELDS),
              (value) => !isNil(value)
            )
        );
      },
      [setValue]
    );

  const handleSetRepliedMessageId = useCallback<(messageId: MessageID) => void>(
    (messageId) => {
      setValue((prevValue) => ({
        ...prevValue,
        repliedMessageId: messageId
      }));
    },
    [setValue]
  );

  const handleAfterCreateMessage = useCallback<() => void>(() => {
    setValue(null);
    setCreateMessageStateKey(generateNanoId());
  }, [setValue]);

  return {
    createMessageStateKey,
    messageInitialValues: value,
    handleSetRepliedMessageId,
    handleAfterCreateMessage,
    handleCreateMessageFormStateChange
  };
}

export default useItemCreateMessageFormState;
