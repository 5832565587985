import React, { useCallback } from 'react';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';

import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import { ItemMessagesListMessageMenuMessage } from '../../ItemMessagesListMessageMenu.types';
import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import {
  TOGGLE_MESSAGE_PINNED_QUERY,
  ToggleMessagePinnedRecordType
} from '../../../../../../../queries/toggleMessagePinned.query';

import { useToggleMessagePinned } from '../../../../../../../hooks/useToggleMessagePinned';

import { PureTooltipIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { ProjectCache } from '../../../../../../../../projects/ProjectCache';
import { TaskCache } from '../../../../../../../../tasks/TaskCache';

import { messagesKeys } from '../../../../../../../../../locales/keys';

interface MessageMenuPinnedButtonProps {
  message: ItemMessagesListMessageMenuMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageMenuPinnedButton({
  message,
  messagesCacheKey
}: MessageMenuPinnedButtonProps) {
  const { toggleMessagePinned, toggleMessagePinnedLoading } =
    useToggleMessagePinned<ToggleMessagePinnedRecordType>({
      cacheKeys: flatten(
        compact([
          messagesCacheKey,
          message.project?.nanoId
            ? [ProjectCache.pinnedMessagesCacheKey(message.project.nanoId)]
            : null,
          message.task?.nanoId
            ? [TaskCache.pinnedMessagesCacheKey(message.task.nanoId)]
            : null
        ])
      ),
      query: TOGGLE_MESSAGE_PINNED_QUERY
    });

  const handleToggleMessagePinned = useCallback<() => void>(
    () => toggleMessagePinned({ uuid: message.uuid }),
    [message.uuid, toggleMessagePinned]
  );

  return (
    <PureTooltipIconButtonHelper
      className={
        message.pinnedAt
          ? 'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-blue-500 dark:text-blue-700 hover:text-blue-600 dark:hover:text-blue-500 focus:ring-offset-0'
          : 'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
      }
      icon={IconsEnum.PUSHPIN_OUTLINE}
      iconClassName="h-5 w-5"
      tooltipI18nText={
        message.pinnedAt ? messagesKeys.unpinMessage : messagesKeys.pinMessage
      }
      tooltipSingleton
      disabled={toggleMessagePinnedLoading}
      onClick={handleToggleMessagePinned}
    />
  );
}

export default MessageMenuPinnedButton;
