import React from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { MessageFields } from '../../../../../messagesTypes';
import { ItemCreateMessageFormData } from '../../hooks/useItemCreateMessageForm';
import { DropzoneUploadedFileItem } from '../../../../../../../helpers/dropzone/DropzoneUploadedFile';

import { ItemCreateMessageAttachmentsList } from './components/ItemCreateMessageAttachmentsList';

interface ItemCreateMessageAttachmentsProps {
  control: Control<ItemCreateMessageFormData>;
  setValue: UseFormSetValue<ItemCreateMessageFormData>;
  allUploadingFiles?: { [id: string]: DropzoneUploadedFileItem };
  onRemoveUploadingFile?: (id: string) => void;
}

function ItemCreateMessageAttachments({
  control,
  setValue,
  allUploadingFiles,
  onRemoveUploadingFile
}: ItemCreateMessageAttachmentsProps) {
  const fileAttachmentIds = useWatch({
    control,
    name: MessageFields.FILE_ATTACHMENT_IDS
  });

  const attachedFileAttachmentIds = useWatch({
    control,
    name: MessageFields.ATTACHED_FILE_ATTACHMENT_IDS
  });

  const selectedLifestyleIds = useWatch({
    control,
    name: MessageFields.SELECTED_LIFESTYLE_IDS
  });

  const selectedProductIds = useWatch({
    control,
    name: MessageFields.SELECTED_PRODUCT_IDS
  });

  const selectedMaterialIds = useWatch({
    control,
    name: MessageFields.SELECTED_MATERIAL_IDS
  });

  const colors = useWatch({
    control,
    name: MessageFields.COLORS
  });

  if (
    every(
      [
        fileAttachmentIds,
        attachedFileAttachmentIds,
        selectedLifestyleIds,
        selectedProductIds,
        selectedMaterialIds,
        colors
      ],
      (attachment) => isEmpty(attachment)
    ) &&
    isEmpty(allUploadingFiles)
  ) {
    return null;
  }

  return (
    <div className="mb-1 overflow-y-hidden overscroll-x-auto py-1">
      <ItemCreateMessageAttachmentsList
        colors={colors}
        setValue={setValue}
        attachedFileAttachmentIds={attachedFileAttachmentIds}
        fileAttachmentIds={fileAttachmentIds}
        selectedProductIds={selectedProductIds}
        selectedLifestyleIds={selectedLifestyleIds}
        selectedMaterialIds={selectedMaterialIds}
        allUploadingFiles={allUploadingFiles}
        onRemoveUploadingFile={onRemoveUploadingFile}
      />
    </div>
  );
}

export default ItemCreateMessageAttachments;
