import React, { useCallback } from 'react';

import { I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../../helpers/Icon';
import { Translate } from '../../Translate';

type PureIconSubmitButtonHelperOnClick = (
  e: React.MouseEvent<HTMLButtonElement>
) => void;

export interface PureIconSubmitButtonHelperDefaultProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  onClick?: PureIconSubmitButtonHelperOnClick;
  onMouseEnter?: () => void;
  onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
}

export interface PureIconSubmitButtonHelperIconProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text?: never;
}

interface PureIconSubmitButtonHelperWithI18nProps {
  icon: IconsEnum | null;
  i18nTextClassName?: string;
  i18nText: I18nText;
  text?: never;
}

interface PureIconSubmitButtonHelperWithTextProps {
  icon: IconsEnum | null;
  i18nTextClassName?: never;
  i18nText?: never;
  text: string;
}

type PureIconSubmitButtonHelperProps = PureIconSubmitButtonHelperDefaultProps &
  (
    | PureIconSubmitButtonHelperIconProps
    | PureIconSubmitButtonHelperWithI18nProps
    | PureIconSubmitButtonHelperWithTextProps
  );

function PureIconSubmitButtonHelper({
  id,
  className,
  disabled,
  icon = null,
  iconClassName,
  onClick,
  onMouseEnter,
  onPointerDown,
  i18nTextClassName,
  i18nText,
  text
}: PureIconSubmitButtonHelperProps) {
  const handleClick = useCallback<PureIconSubmitButtonHelperOnClick>(
    (e) => {
      onClick?.(e);
    },
    [onClick]
  );

  return (
    <button
      id={id}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onPointerDown={onPointerDown}
      type="submit"
    >
      {icon ? (
        <Icon
          className={iconClassName}
          icon={icon}
          id={id ? `${id}-icon` : undefined}
        />
      ) : null}
      {icon && i18nText ? ' ' : null}
      {i18nText && i18nTextClassName ? (
        <span className={i18nTextClassName}>
          <Translate id={i18nText} />
        </span>
      ) : null}
      {i18nText && !i18nTextClassName ? <Translate id={i18nText} /> : null}
      {text}
    </button>
  );
}

export default PureIconSubmitButtonHelper;
