import { ProductID, ProductNanoID } from '../../../../productsTypes';

import {
  FETCH_PRODUCT_INFO_QUERY,
  FetchProductInfoQueryResponse
} from '../../../../queries/fetchProductInfo.query';

import { useProduct } from '../../../../hooks/useProduct';

import { DateTimeHelper } from '../../../../../../helpers/DateTimeHelper';
import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../helpers/Translate';
import { UserPureLink } from '../../../../../common/helpers/UserPureLink';

import { ProductCache } from '../../../../ProductCache';
import { ProductPath } from '../../../../ProductPath';

import {
  formsFields,
  productsKeys,
  words
} from '../../../../../../locales/keys';

interface ProductInfoProps {
  productId: ProductID;
}
function ProductInfo({ productId }: ProductInfoProps) {
  const { product } = useProduct<FetchProductInfoQueryResponse>({
    productNanoId: productId as ProductNanoID,
    cacheKey: ProductCache.shortInfoCacheKey(),
    query: FETCH_PRODUCT_INFO_QUERY
  });

  if (product) {
    return (
      <div>
        <Translate id={formsFields.created} />
        &nbsp;
        <NextPureLinkHelper
          className="underline"
          href={ProductPath.show(product.nanoId)}
          i18nText={product.name}
        />
        &nbsp;
        <Translate id={words.by} />
        &nbsp;
        <UserPureLink
          className="underline"
          i18nText={product.user?.fullName}
          user={product.user}
        />
        &nbsp;
        <Translate id={words.on} />
        &nbsp;
        <DateTimeHelper date={product.createdAt} />
      </div>
    );
  }

  return <Translate id={productsKeys.created} />;
}

export default ProductInfo;
