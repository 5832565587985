import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { useCreateChatGptCompletion } from '../../../chatGptCompletions/hooks/useCreateChatGptCompletion';
import { useMessageCommand } from '../useMessageCommand';
import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { ChatGptCompletionMessageContentTypeEnum } from '../../../chatGptCompletions/chatGptCompletionsTypes/chatGptCompletionsFieldsTypes';
import { MessageCommandsEnum } from '../../messagesTypes';
import {
  ChatGptCompletionResponseBodyType,
  CREATE_CHAT_GPT_COMPLETION_QUERY,
  CreateChatGptCompletionQueryResponse
} from '../../../chatGptCompletions/queries/createChatGptCompletion.query';
import { TodoItemsType } from '../../../../utils/todoItemsFromText/todoItemsFromText';

import { S3MultipartDropzoneAreaOnFileUploaded } from '../../../../helpers/dropzone/S3MultipartDropzoneArea';

import { Files } from '../../../../utils/Files';
import { pdfToImageBase64 } from '../../../../utils/pdfToImageBase64';
import { todoItemsFromText } from '../../../../utils/todoItemsFromText';

import { ChatGptCompletionCache } from '../../../chatGptCompletions/ChatGptCompletionCache';
import { messageCommandsData } from '../../messagesConstants';

export type HandleMessageCommandReturnType = {
  messageBody: string;
  todoItems: TodoItemsType;
};

function useProcessMessageCommand() {
  const { messageCommand, clearMessageCommand } = useMessageCommand();

  const [uploadedFile, setUploadedFile] = useState<string>(null);

  const [repliedMessageFile, setRepliedMessageFile] = useState<string>(null);

  const [pdfParsing, setPdfParsing] = useState<boolean>(false);

  const handleSetRepliedMessageFile = useCallback<(fileUrl: string) => void>(
    (fileUrl) => setRepliedMessageFile(fileUrl),
    []
  );

  const handleSetUploadedFile =
    useCallback<S3MultipartDropzoneAreaOnFileUploaded>(
      (_id, _uploadedId, updatedFile) => setUploadedFile(updatedFile),
      []
    );

  const {
    handleCreateChatGptCompletion,
    createChatGptCompletionLoading,
    createChatGptCompletionErrorMessage
  } = useCreateChatGptCompletion<CreateChatGptCompletionQueryResponse>({
    cacheKeys: [ChatGptCompletionCache.createCacheKey()],
    query: CREATE_CHAT_GPT_COMPLETION_QUERY
  });

  useShowToastOnErrorChange({ error: createChatGptCompletionErrorMessage });

  const handleChatGptCommand = useCallback<
    (userContentText: string) => Promise<string>
  >(
    async (userContentText: string) => {
      if (!messageCommand) {
        return null;
      }

      let imagesData: string[] = null;

      if (messageCommand === MessageCommandsEnum.TODO_LIST_FROM_PDF) {
        setPdfParsing(true);

        if (Files.isPDF(uploadedFile || repliedMessageFile)) {
          imagesData = await pdfToImageBase64(
            uploadedFile || repliedMessageFile
          );
        } else if (Files.isImage(uploadedFile || repliedMessageFile)) {
          imagesData = [uploadedFile || repliedMessageFile];
        }

        setPdfParsing(false);
      }

      const userContent = isEmpty(imagesData)
        ? userContentText
        : map(imagesData, (imageData) => ({
            type: ChatGptCompletionMessageContentTypeEnum.IMAGE_URL,
            image_url: { url: imageData }
          }));

      const response = await handleCreateChatGptCompletion(
        userContent,
        messageCommandsData[messageCommand].systemContent
      );

      const responseBody = JSON.parse(
        JSON.stringify(response?.createChatGptCompletion?.record?.responseBody)
      ) as ChatGptCompletionResponseBodyType;

      clearMessageCommand();

      return responseBody?.choices?.[0]?.message?.content as string;
    },
    [
      clearMessageCommand,
      handleCreateChatGptCompletion,
      messageCommand,
      repliedMessageFile,
      uploadedFile
    ]
  );

  const handleMessageCommand = useCallback<
    (
      messageBody: string,
      visibleForClient?: boolean
    ) => Promise<HandleMessageCommandReturnType>
  >(
    async (messageBody, visibleForClient) => {
      let todoItems = undefined;

      if (!messageCommand) {
        return { messageBody, todoItems };
      }

      const chatGptResponseContent = await handleChatGptCommand(messageBody);

      if (messageCommand === MessageCommandsEnum.TODO_LIST_FROM_PDF) {
        todoItems = todoItemsFromText(chatGptResponseContent, visibleForClient);

        return {
          messageBody: '',
          todoItems
        };
      }

      return {
        messageBody: chatGptResponseContent,
        todoItems
      };
    },
    [handleChatGptCommand, messageCommand]
  );

  return {
    commandProcessing: createChatGptCompletionLoading || pdfParsing,
    handleMessageCommand,
    handleSetRepliedMessageFile,
    handleSetUploadedFile,
    messageCommand,
    pdfParsing
  };
}

export default useProcessMessageCommand;
