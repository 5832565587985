import {
  FileAttachmentFile,
  FileAttachmentID,
  FileAttachmentName
} from '../../../../../../../../fileAttachments/fileAttachmentsTypes';
import {
  SelectedLifestyleID,
  SelectedLifestyleLifestyleImageFile,
  SelectedLifestyleLifestyleName
} from '../../../../../../../../selectedLifestyles/selectedLifestylesTypes';
import {
  SelectedProductID,
  SelectedProductProductImageFile,
  SelectedProductProductName
} from '../../../../../../../../selectedProducts/selectedProductsTypes';
import {
  SelectedMaterialID,
  SelectedMaterialMaterialImageFile,
  SelectedMaterialMaterialName
} from '../../../../../../../../selectedMaterials/selectedMaterialsTypes';

export interface ItemCreateMessageAttachmentsListAttachFile {
  id: FileAttachmentID;
  name: FileAttachmentName;
  file: FileAttachmentFile;
}

export interface ItemCreateMessageAttachmentsListSelectProduct {
  id: SelectedProductID;
  product: {
    name: SelectedProductProductName;
    image: {
      file: SelectedProductProductImageFile;
    };
  };
}

export interface ItemCreateMessageAttachmentsListSelectLifestyle {
  id: SelectedLifestyleID;
  lifestyle: {
    name: SelectedLifestyleLifestyleName;
    image: {
      file: SelectedLifestyleLifestyleImageFile;
    };
  };
}

export interface ItemCreateMessageAttachmentsListSelectMaterial {
  id: SelectedMaterialID;
  material: {
    name: SelectedMaterialMaterialName;
    image: {
      file: SelectedMaterialMaterialImageFile;
    };
  };
}

export enum ItemCreateMessageAttachmentsListImageTypes {
  SELECTED_PRODUCT = 'selected_product',
  SELECTED_LIFESTYLE = 'selected_lifestyle',
  SELECTED_MATERIAL = 'selected_material',
  FILE_ATTACHMENT = 'file_attachment'
}
