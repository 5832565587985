import { useMessageCommand } from '../../../../../hooks/useMessageCommand';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { IsLoading } from '../../../../../../../types';

import { Icon } from '../../../../../../../helpers/Icon';
import { Loading } from '../../../../../../../helpers/Loading';
import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { Translate } from '../../../../../../../helpers/Translate';

import { messageCommandsData } from '../../../../../messagesConstants';

import { messagesKeys, words } from '../../../../../../../locales/keys';

interface ItemCreateMessageCommandHeaderProps {
  isLoading?: IsLoading;
}

function ItemCreateMessageCommandHeader({
  isLoading
}: ItemCreateMessageCommandHeaderProps) {
  const { messageCommand, clearMessageCommand } = useMessageCommand();

  if (!messageCommand) {
    return null;
  }

  return (
    <div
      id="command-header"
      className="col-span-full py-1 mb-1 px-2 dark text-white flex gap-2 rounded-md bg-purple-600/60 dark:bg-purple-600/60 justify-between"
    >
      <div className="row flex gap-2">
        <Icon icon={IconsEnum.COMMAND_OUTLINE} />

        <span className="leading-4 text-xs">
          <Translate id={messageCommandsData[messageCommand].i18nTitle} />
        </span>

        <PureTooltipIconButtonHelper
          className="p-0 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 text-white/50 dark:text-white/50"
          icon={IconsEnum.X_CIRCLE}
          iconClassName="h-4 w-4"
          onClick={clearMessageCommand}
          tooltipI18nText={messagesKeys.removeCommand}
          tooltipPlacement={TooltipPlacement.TOP}
        />
      </div>

      {isLoading && (
        <div className="text-xs flex gap-2">
          <Translate id={words.processing} />
          <Loading addClass="inline" size="xs" />
        </div>
      )}
    </div>
  );
}

export default ItemCreateMessageCommandHeader;
