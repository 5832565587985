import { useState } from 'react';

import {
  ItemMessagesListMessageFileFileAttachment,
  ItemMessagesListMessageFileMessage
} from './ItemMessagesListMessageFile.types';
import { FetchMessagesCacheKey } from '../../../../../messagesTypes';

import { MessagesListMessageFileAttachmentButtons } from '../../../MessagesListMessage/buttons/MessagesListMessageFileAttachmentButtons';

import { Tooltip } from '../../../../../../../helpers/tooltips/Tooltip';
import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonTarget } from '../../../../../../../helpers/tooltips/TooltipSingletonTarget';

import { Files } from '../../../../../../../utils/Files';
import { ItemMessagesListMessageFile3dModel } from './ItemMessagesListMessageFile3dModel';
import { ItemMessagesListMessageFileBase } from './ItemMessagesListMessageFileBase';

interface ItemMessagesListMessageFileProps {
  messagesCacheKey?: FetchMessagesCacheKey;
  fileAttachment: ItemMessagesListMessageFileFileAttachment;
  message: ItemMessagesListMessageFileMessage;
  isVisibleForClient: boolean;
  tooltipSingleton?: boolean;
}

function ItemMessagesListMessageFile({
  messagesCacheKey,
  fileAttachment,
  message,
  isVisibleForClient,
  tooltipSingleton
}: ItemMessagesListMessageFileProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLSpanElement | null>(null);

  const tooltipView = fileAttachment.name ? (
    <Tooltip
      placement={TooltipPlacement.TOP}
      referenceElement={referenceTooltipElement}
      className="break-words"
      withArrow
    >
      <div className="break-words">{fileAttachment.name}</div>
    </Tooltip>
  ) : null;

  const tooltipSingletonView = fileAttachment.name ? (
    <TooltipSingletonTarget referenceElement={referenceTooltipElement}>
      <div className="break-words">{fileAttachment.name}</div>
    </TooltipSingletonTarget>
  ) : null;

  return (
    <div
      className="group/item flex-grow max-w-60 relative"
      ref={setReferenceTooltipElement}
    >
      <div className="flex gap-1 relative h-full rounded bg-gray-100 dark:bg-gray-850 hover:bg-gray-200 dark:hover:bg-gray-800 p-2 pr-3 focus-within:ring-base focus-within:ring-offset-0 overflow-hidden transition-colors duration-150 ease-out">
        {Files.isPreview3dModel(fileAttachment.file) ? (
          <ItemMessagesListMessageFile3dModel
            fileAttachment={fileAttachment}
            message={message}
            isVisibleForClient={isVisibleForClient}
          />
        ) : (
          <ItemMessagesListMessageFileBase
            fileAttachment={fileAttachment}
            isVisibleForClient={isVisibleForClient}
          />
        )}
      </div>
      <MessagesListMessageFileAttachmentButtons
        addClassName="bottom-0"
        messagesCacheKey={messagesCacheKey}
        fileAttachment={fileAttachment}
        message={message}
      />
      {tooltipSingleton ? tooltipSingletonView : tooltipView}
    </div>
  );
}

export default ItemMessagesListMessageFile;
