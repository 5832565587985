import React from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { MessageFields } from '../../../../../messagesTypes';
import { ItemCreateMessageFormData } from '../../hooks/useItemCreateMessageForm';

import { ItemCreateMessageRepliedMessageContent } from './components/ItemCreateMessageRepliedMessageContent';

interface ItemCreateMessageRepliedMessageProps {
  control: Control<ItemCreateMessageFormData>;
  setValue: UseFormSetValue<ItemCreateMessageFormData>;
}

function ItemCreateMessageRepliedMessage({
  control,
  setValue
}: ItemCreateMessageRepliedMessageProps) {
  const repliedMessageId = useWatch({
    control,
    name: MessageFields.REPLIED_MESSAGE_ID
  });

  if (!repliedMessageId) {
    return null;
  }

  return (
    <ItemCreateMessageRepliedMessageContent
      messageId={repliedMessageId}
      setValue={setValue}
    />
  );
}

export default ItemCreateMessageRepliedMessage;
