import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

import { ItemMessagesListMessageMenuMessage } from '../../ItemMessagesListMessageMenu.types';
import { OnEditMessageAction } from '../../../../../../../messagesTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../../../../../locales/keys';

interface MessageMenuEditButtonProps {
  message: ItemMessagesListMessageMenuMessage;
  onEditMessage: OnEditMessageAction;
}

function MessageMenuEditButton({
  message,
  onEditMessage
}: MessageMenuEditButtonProps) {
  const handleClick = useCallback<() => void>(() => {
    onEditMessage(message);
  }, [message, onEditMessage]);

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:ring-gray-900 text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 flex h-6 items-center px-0.5 ring ring-white rounded-full"
      icon={IconsEnum.PENCIL}
      iconClassName="h-5 w-5"
      tooltipI18nText={words.edit}
      tooltipSingleton
      onClick={handleClick}
    />
  );
}

export default MessageMenuEditButton;
