import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { Translate } from '../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../helpers/Icon';

import { tasksKeys } from '../../../../../../../locales/keys';

function ItemCreateMessageIsHiddenTask() {
  return (
    <div className="max-w-full flex items-center justify-center gap-x-1">
      <Icon icon={IconsEnum.EYE_OFF_SOLID} className="w-4 h-4 shrink-0" />
      <span>
        <Translate id={tasksKeys.thisTaskIsHidden} />
      </span>
    </div>
  );
}

export default ItemCreateMessageIsHiddenTask;
