import React from 'react';

import { Tooltip } from '../../../../../helpers/tooltips/Tooltip';
import { DateTimeHelper } from '../../../../../helpers/DateTimeHelper';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface TooltipMessageDateProps {
  referenceElement: HTMLDivElement | null;
  date: string;
  placement?: TooltipPlacement;
}

function TooltipMessageDate({
  referenceElement,
  date,
  placement = TooltipPlacement.LEFT
}: TooltipMessageDateProps) {
  return (
    <Tooltip
      withArrow
      placement={placement}
      referenceElement={referenceElement}
    >
      <DateTimeHelper date={date} />
    </Tooltip>
  );
}

export default TooltipMessageDate;
