import { useCallback, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import size from 'lodash/size';
import uniq from 'lodash/uniq';

import { MessageFields } from '../../../../../messagesTypes';
import { ItemCreateMessageFormData } from '../useItemCreateMessageForm/useItemCreateMessageForm.types';
import { LexicalFieldValueType } from '../../../../../../../helpers/FormFields/LexicalEditorField/LexicalEditorField';

import {
  FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY,
  FetchLifestylesSetByUserIdQueryResponse
} from '../../../../../../lifestylesSets/queries/fetchLifestylesSetByUserId.query';
import { TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY } from '../../../../../../lifestylesSets/queries/toggleLifestyleInLifestylesSet.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useLifestylesSetByUserId } from '../../../../../../lifestylesSets/hooks/useLifestylesSetByUserId';
import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useToggleLifestyleInLifestylesSet } from '../../../../../../lifestylesSets/hooks/useToggleLifestyleInLifestylesSet';

import { LifestylesSetCache } from '../../../../../../lifestylesSets/LifestylesSetCache';
import { LifestylePath } from '../../../../../../lifestyles/LifestylePath';

function findLifestyleIds(text: string) {
  const baseUrl = window.location.origin;
  const path = `${LifestylePath.index()}/`;
  const idPattern = /[a-zA-Z0-9]{8}/;
  const idPatternFromUrl = /([a-zA-Z0-9]{8})$/;

  const fullPattern = new RegExp(
    baseUrl.replace(/\//g, '\\/') +
      path.replace(/\//g, '\\/') +
      idPattern.source,
    'g'
  );

  const urls = text.match(fullPattern);

  return urls?.map((url) => url.match(idPatternFromUrl)?.[0]) ?? [];
}

interface ItemCreateMessageFormStateOptions {
  control: Control<ItemCreateMessageFormData>;
  setValue: UseFormSetValue<ItemCreateMessageFormData>;
}

function useItemCreateMessageFormAddLifestyleFromLink({
  control,
  setValue
}: ItemCreateMessageFormStateOptions) {
  const [detectedLifestyleIds, setDetectedLifestyleIds] = useState<string[]>(
    []
  );

  const currentUser = useCurrentUser();

  const selectedLifestyleIdsField = useWatch({
    control,
    name: MessageFields.SELECTED_LIFESTYLE_IDS
  });

  const handleChangeSelectedLifestyles = useCallback(
    (lifestylesSetByUserId: FetchLifestylesSetByUserIdQueryResponse) => {
      const uniqueDetectedLifestyle = filter(
        lifestylesSetByUserId?.selectedLifestyles,
        (selectedLifestyle) =>
          !selectedLifestyleIdsField.includes(selectedLifestyle.id) &&
          detectedLifestyleIds.includes(selectedLifestyle.lifestyle.nanoId)
      );

      const selectedLifestyleIds = map(uniqueDetectedLifestyle, 'id');

      if (size(selectedLifestyleIds) > 0) {
        setValue(MessageFields.SELECTED_LIFESTYLE_IDS, [
          ...selectedLifestyleIdsField,
          ...selectedLifestyleIds
        ]);
      }
    },
    [detectedLifestyleIds, selectedLifestyleIdsField, setValue]
  );

  const resetDetectedLifestyle = useCallback(() => {
    size(detectedLifestyleIds) > 0 && setDetectedLifestyleIds([]);
  }, [detectedLifestyleIds]);

  const {
    toggleLifestyleInLifestylesSetErrorMessage,
    toggleLifestyleInLifestylesSet
  } = useToggleLifestyleInLifestylesSet({
    query: TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY,
    cacheKeys: [LifestylesSetCache.showCacheKey()]
  });

  const { lifestylesSetError, lifestylesSet } =
    useLifestylesSetByUserId<FetchLifestylesSetByUserIdQueryResponse>({
      cacheKey: LifestylesSetCache.showCacheKey(),
      query: FETCH_LIFESTYLES_SET_BY_USER_ID_QUERY,
      userId: currentUser.get('id'),
      options: {
        onSuccess: (data) =>
          handleChangeSelectedLifestyles(data?.lifestylesSetByUserId)
      }
    });

  useShowToastOnErrorChange({
    error: lifestylesSetError || toggleLifestyleInLifestylesSetErrorMessage
  });

  const detectLifestyle = useCallback(
    (lexical: LexicalFieldValueType) => {
      const foundLifestyleIds = findLifestyleIds(lexical?.text || '');

      const uniqFoundLifestyleIds = uniq(foundLifestyleIds);

      const selectedLifestyles = lifestylesSet?.selectedLifestyles;

      if (!isEqual(detectedLifestyleIds, uniqFoundLifestyleIds)) {
        for (const lifestyleId of uniqFoundLifestyleIds) {
          if (!detectedLifestyleIds.includes(lifestyleId)) {
            const selectedLifestyle = selectedLifestyles?.find(
              (selectedLifestyle) =>
                selectedLifestyle.lifestyle.nanoId === lifestyleId
            );

            if (selectedLifestyle) {
              setValue(MessageFields.SELECTED_LIFESTYLE_IDS, [
                ...selectedLifestyleIdsField,
                selectedLifestyle.id
              ]);
            } else {
              // add new lifestyles
              toggleLifestyleInLifestylesSet({
                uuid: lifestylesSet?.uuid,
                lifestyleId
              });
            }
          }
        }
      }

      setDetectedLifestyleIds(uniqFoundLifestyleIds);
    },
    [
      detectedLifestyleIds,
      lifestylesSet?.selectedLifestyles,
      lifestylesSet?.uuid,
      selectedLifestyleIdsField,
      setValue,
      toggleLifestyleInLifestylesSet
    ]
  );

  return {
    detectLifestyle,
    resetDetectedLifestyle
  };
}

export default useItemCreateMessageFormAddLifestyleFromLink;
