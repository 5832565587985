import React, { useCallback } from 'react';

import { HexColor } from '../../../../../../../../../types';

import { PureIconButtonHelper } from '../../../../../../../../../helpers/buttons/PureIconButtonHelper';
import { Icon } from '../../../../../../../../../helpers/Icon';

import { IconsEnum } from '../../../../../../../../../assets/icons/types';

interface ItemCreateMessageAttachmentsListCopyColorButtonProps {
  color: HexColor;
  removeColor: (color: HexColor) => void;
}

function ItemCreateMessageAttachmentsListCopyColorButton({
  color,
  removeColor
}: ItemCreateMessageAttachmentsListCopyColorButtonProps) {
  const handleRemoveColor = useCallback(
    () => removeColor(color),
    [color, removeColor]
  );

  return (
    <div className="h-16 relative rounded bg-gray-100 dark:bg-gray-850 group/item w-16">
      <PureIconButtonHelper
        className="absolute top-0 right-0 p-1 rounded-sm sm:opacity-0 group-hover/item:opacity-100 focus:opacity-100 bg-opacity-50 dark:hover:bg-opacity-100 hover:bg-opacity-100 text-gray-700 bg-white dark:bg-gray-900 dark:bg-opacity-50 dark:text-gray-300 focus:ring-offset-0"
        icon={IconsEnum.X}
        iconClassName="h-4 w-4"
        onClick={handleRemoveColor}
      />

      <div className="w-full h-full rounded" style={{ background: color }} />

      <div className="absolute bottom-0 left-0 p-1 bg-gray-600 text-white flex rounded-bl rounded-tr">
        <Icon icon={IconsEnum.COLOR_SWATCH_OUTLINE} className="w-5 h-5" />
      </div>
    </div>
  );
}

export default ItemCreateMessageAttachmentsListCopyColorButton;
