function getDifferenceInMinutes(
  date: Date | string | null,
  prevDate: Date | string | null
): number | null {
  const date1 = new Date(prevDate).getTime();
  const date2 = new Date(date).getTime();
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

export default getDifferenceInMinutes;
