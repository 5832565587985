import { gql } from 'graphql-request';

import {
  MessageID,
  MessageUUID,
  MessageBody,
  MessageColors,
  MessageCreatedAt,
  MessageUserUUID,
  MessageUserFullName,
  MessageFileAttachmentsUUID,
  MessageSelectedProductsUUID,
  MessageSelectedLifestylesUUID,
  MessageSelectedMaterialsUUID,
  MessageTodoItemUUID
} from '../messagesTypes';

export interface FetchRepliedFinMessageQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  colors: MessageColors;
  createdAt: MessageCreatedAt;
  user: {
    uuid: MessageUserUUID;
    fullName: MessageUserFullName;
  };
  fileAttachments: {
    uuid: MessageFileAttachmentsUUID;
  }[];
  selectedProducts: {
    uuid: MessageSelectedProductsUUID;
  }[];
  selectedLifestyles: {
    uuid: MessageSelectedLifestylesUUID;
  }[];
  selectedMaterials: {
    uuid: MessageSelectedMaterialsUUID;
  }[];
  forwardedMessage?: {
    uuid: MessageUUID;
    body: MessageBody;
  };
  repliedMessage?: {
    uuid: MessageUUID;
    body: MessageBody;
  };
  todoItems: {
    uuid: MessageTodoItemUUID;
  }[];
}

export const FETCH_REPLIED_FIN_MESSAGE_QUERY = gql`
  query RepliedFinMessage($uuid: ID!) {
    message(uuid: $uuid) {
      id
      uuid
      body
      colors
      createdAt
      user {
        uuid
        fullName
      }
      fileAttachments {
        uuid
      }
      selectedProducts {
        uuid
      }
      selectedLifestyles {
        uuid
      }
      selectedMaterials {
        uuid
      }
      forwardedMessage {
        uuid
        body
      }
      repliedMessage {
        uuid
        body
      }
      todoItems {
        uuid
      }
    }
  }
`;
