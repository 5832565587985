import React from 'react';
import isEmpty from 'lodash/isEmpty';
import cl from 'classnames';

import { ItemMessagesListMessageForwardedMessageColorsMessage } from './ItemMessagesListMessageForwardedMessageColors.types';

import { ColorButtons } from '../../../../../../../../../helpers/colors/ColorButtons';

interface ItemMessagesListMessageForwardedMessageColorsProps {
  message: ItemMessagesListMessageForwardedMessageColorsMessage;
  reverse?: boolean;
}

function ItemMessagesListMessageForwardedMessageColors({
  message,
  reverse
}: ItemMessagesListMessageForwardedMessageColorsProps) {
  const isVisibleForClient = !message.body && message.visibleForClient;

  if (isEmpty(message.colors)) {
    return null;
  }

  return (
    <div className="mt-0.5">
      <div
        className={cl('text-sm overflow-hidden rounded-xl', {
          'rounded-tr rounded-br': !reverse,
          'rounded-tl rounded-bl': reverse
        })}
      >
        <ColorButtons
          colors={message.colors}
          isVisibleForClient={isVisibleForClient}
        />
      </div>
    </div>
  );
}

export default ItemMessagesListMessageForwardedMessageColors;
