import React, { Fragment, useState } from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';
import {
  FetchMessagesCacheKey,
  MessageClassifications
} from '../../../../../messagesTypes';
import { ItemMessagesListMessageBodyMessage } from './ItemMessagesListMessageBody.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListMessageSendEmailNotificationButton } from '../ItemMessagesListMessageSendEmailNotificationButton';
import { ItemMessagesListMessageWhiteboardAssociatedImage } from '../ItemMessagesListMessageWhiteboardAssociatedImage';
import { MessageOpenInWhiteboardLink } from '../MessageOpenInWhiteboardLink';

import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';
import { Icon } from '../../../../../../../helpers/Icon';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { whiteboardsKeys } from '../../../../../../../locales/keys';

interface ItemMessagesListMessageBodyProps {
  message: ItemMessagesListMessageBodyMessage;
  messagesCacheKey?: FetchMessagesCacheKey;
  nextSameAuthor: boolean;
  prevSameAuthor: boolean;
  reverse?: boolean;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  withoutOpenInWhiteboardLink?: boolean;
  markedUnhappyMessages?: boolean;
}

function ItemMessagesListMessageBody({
  message,
  messagesCacheKey,
  nextSameAuthor,
  prevSameAuthor,
  reverse,
  togglePreventModalClose,
  withoutOpenInWhiteboardLink,
  markedUnhappyMessages
}: ItemMessagesListMessageBodyProps) {
  const currentUser = useCurrentUser();
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const unhappy =
    (message.classification === MessageClassifications.UNHAPPY ||
      message.classification === MessageClassifications.MOST_LIKELY_UNHAPPY) &&
    markedUnhappyMessages &&
    !message.aiNotRelevantAt;

  return (
    <Fragment>
      <div
        ref={setReferenceTooltipElement}
        className={cl(
          'inline-flex px-3 py-2 rounded-xl text-sm sm:text-md leading-snug max-w-xl relative',
          {
            'bg-gray-200 dark:bg-gray-700': !message.visibleForClient,
            'bg-blue-100 dark:bg-gray-300 dark:text-black':
              message.visibleForClient && !message.user?.client,
            'bg-blue-100 border-blue-500 border-l-8 dark:text-black':
              message.visibleForClient && message.user?.client && !unhappy,
            'bg-red-100 border-red-500 border-l-8 dark:text-black':
              message.visibleForClient && message.user?.client && unhappy,
            'ring-1 ring-white dark:ring-gray-900':
              currentUser.hasPermissions(
                MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE
              ) && !isEmpty(message.forwardedMessage),
            'rounded-tl': prevSameAuthor && !reverse,
            'rounded-bl': nextSameAuthor && !reverse,
            'rounded-tr': prevSameAuthor && reverse,
            'rounded-br': nextSameAuthor && reverse
          }
        )}
      >
        {message.visibleForClient && message.user?.client && unhappy && (
          <div className="absolute flex -left-6 -top-0.5 text-red-500 rounded-full bg-white dark:bg-gray-900">
            <Icon icon={IconsEnum.EMOJI_SAD_SOLID} />
          </div>
        )}

        {message.whiteboardKeyId ? (
          <div className="border-r-2 pr-2 mr-2 border-gray-500/50">
            {message.fileAttachment ? (
              <ItemMessagesListMessageWhiteboardAssociatedImage
                message={message}
                togglePreventModalClose={togglePreventModalClose}
              />
            ) : null}
          </div>
        ) : null}
        <div>
          <MarkdownHelper
            source={message.body}
            className={cl(
              'break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug',
              {
                'dark:markdown-invert': !message.user?.client
              }
            )}
            fromMessage
            linkTarget={
              currentUser.hasPermissions(
                MessagesPermissions.READ_MESSAGE_LINK_BLANK
              )
                ? '_blank'
                : undefined
            }
          />

          <div className="flex items-center gap-3">
            {!withoutOpenInWhiteboardLink &&
              message.whiteboardKeyId &&
              message.threadKeyId && (
                <CheckPermissions
                  action={
                    MessagesPermissions.READ_MESSAGE_OPEN_IN_WHITEBOARD_ICON_LINK
                  }
                >
                  <MessageOpenInWhiteboardLink
                    className="hover:underline text-blue-500 text-xs"
                    divClassName="flex items-center gap-1"
                    message={message}
                    i18nText={whiteboardsKeys.open}
                    icon={IconsEnum.EXTERNAL_LINK_SOLID}
                    iconClassName="h-3.5 w-3.5"
                  />
                </CheckPermissions>
              )}
            <ItemMessagesListMessageSendEmailNotificationButton
              message={message}
              messagesCacheKey={messagesCacheKey}
              className="flex items-center"
              messageSendEmailNotificationButtonClassName={
                message.visibleForClient
                  ? 'text-xs text-gray-700 flex items-center dark:text-black'
                  : null
              }
            />
          </div>
        </div>
      </div>
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </Fragment>
  );
}

export default ItemMessagesListMessageBody;
