import React, { Fragment, useMemo, useState } from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import find from 'lodash/find';

import { FetchTodoItemsSortTypes } from '../../../../../../todoItems/todoItemsTypes';
import { ItemMessagesListMessageTodoListMessage } from './ItemMessagesListMessageTodoList.types';

import {
  FETCH_TODO_LIST_TODO_ITEMS,
  FetchTodoListTodoItemsQueryResponse
} from '../../../../../../todoItems/queries/fetchTodoListTodoItems.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { usePaginatedTodoItems } from '../../../../../../todoItems/hooks/usePaginatedTodoItems';

import { ItemMessagesListMessageTodoListItem } from './components/ItemMessagesListMessageTodoListItem';

import { TooltipMessageDate } from '../../../../tooltips/TooltipMessageDate';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { TodoItemsCache } from '../../../../../../todoItems/TodoItemsCache';

import { sortTodoItemsByOrder } from '../../../../../../todoItems/utils/sortTodoItemsByOrder';
import { TODO_ITEM_NAME_TITLE } from '../../../../../../todoItems/todoItemsConstants';

const regexTodoItemNameAsTitle = new RegExp(`^\\${TODO_ITEM_NAME_TITLE}`);

interface ItemMessagesListMessageTodoListProps {
  message: ItemMessagesListMessageTodoListMessage;
  reverse?: boolean;
  prevSameAuthor: boolean;
  nextSameAuthor: boolean;
}

function ItemMessagesListMessageTodoList({
  message,
  reverse,
  prevSameAuthor,
  nextSameAuthor
}: ItemMessagesListMessageTodoListProps) {
  const currentUser = useCurrentUser();
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLDivElement | null>(null);

  const cacheKey = TodoItemsCache.messageItemsCacheKey(message.uuid);

  const { todoItems, updateTodoItemCache } =
    usePaginatedTodoItems<FetchTodoListTodoItemsQueryResponse>({
      cacheKey,
      query: FETCH_TODO_LIST_TODO_ITEMS,
      initialFilters: { messageUuid: message.uuid },
      initialSort: [FetchTodoItemsSortTypes.ID_ASC],
      initialLimit: 1000,
      options: {
        withoutPrefetch: true
      }
    });

  const todoListItems = useMemo(
    () =>
      filter(
        sortTodoItemsByOrder(
          isEmpty(todoItems) ? message.todoItems : todoItems
        ),
        (item) => !regexTodoItemNameAsTitle.test(item.name)
      ),
    [message.todoItems, todoItems]
  );

  const todoTitle = useMemo(() => {
    const titleTodoItem = find(
      isEmpty(todoItems) ? message.todoItems : todoItems,
      (item) => regexTodoItemNameAsTitle.test(item.name)
    );

    return titleTodoItem?.name.replace(regexTodoItemNameAsTitle, '');
  }, [message.todoItems, todoItems]);

  return (
    <Fragment>
      <div
        ref={setReferenceTooltipElement}
        className={cl(
          'inline-flex px-3 py-2 rounded-xl text-sm sm:text-md leading-snug max-w-xl relative',
          {
            'bg-gray-200 dark:bg-gray-700': !message.visibleForClient,
            'bg-blue-100 dark:bg-gray-300 dark:text-black':
              message.visibleForClient && !message.user?.client,
            'bg-blue-100 border-blue-500 border-l-8 dark:text-black':
              message.visibleForClient && message.user?.client,
            'ring-1 ring-white dark:ring-gray-900':
              currentUser.hasPermissions(
                MessagesPermissions.READ_MESSAGE_FORWARD_MESSAGE
              ) && !isEmpty(message.forwardedMessage),
            'rounded-tl': prevSameAuthor && !reverse,
            'rounded-bl': nextSameAuthor && !reverse,
            'rounded-tr': prevSameAuthor && reverse,
            'rounded-br': nextSameAuthor && reverse
          }
        )}
      >
        <div>
          <div className="break-wrap leading-snug markdown markdown-sm max-w-full dark:markdown-invert">
            <div className="px-1">
              {todoTitle && (
                <div className="mb-2">
                  <div className="font-semibold px-8 py-1 text-base">
                    {todoTitle}
                  </div>
                </div>
              )}

              {todoListItems.map((todoItem) => (
                <ItemMessagesListMessageTodoListItem
                  key={todoItem.id}
                  cacheKeys={[cacheKey]}
                  todoItem={todoItem}
                  updateTodoItemCache={updateTodoItemCache}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <TooltipMessageDate
        referenceElement={referenceTooltipElement}
        date={message.createdAt}
      />
    </Fragment>
  );
}

export default ItemMessagesListMessageTodoList;
