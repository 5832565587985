import { useCallback, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import size from 'lodash/size';
import uniq from 'lodash/uniq';

import { MessageFields } from '../../../../../messagesTypes';
import { ItemCreateMessageFormData } from '../useItemCreateMessageForm/useItemCreateMessageForm.types';
import { LexicalFieldValueType } from '../../../../../../../helpers/FormFields/LexicalEditorField/LexicalEditorField';

import {
  FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
  FetchProductsSetByUserIdQueryResponse
} from '../../../../../../productsSets/queries/fetchProductsSetByUserId.query';
import { TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY } from '../../../../../../productsSets/queries/toggleProductInProductsSet.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useProductsSetByUserId } from '../../../../../../productsSets/hooks/useProductsSetByUserId';
import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useToggleProductInProductsSet } from '../../../../../../productsSets/hooks/useToggleProductInProductsSet';

import { ProductsSetCache } from '../../../../../../productsSets/ProductsSetCache';
import { ProductPath } from '../../../../../../products/ProductPath';

function findProductIds(text: string) {
  const baseUrl = window.location.origin;
  const path = `${ProductPath.index()}/`;
  const idPattern = /[a-zA-Z0-9]{8}/;
  const idPatternFromUrl = /([a-zA-Z0-9]{8})$/;

  const fullPattern = new RegExp(
    baseUrl.replace(/\//g, '\\/') +
      path.replace(/\//g, '\\/') +
      idPattern.source,
    'g'
  );

  const urls = text.match(fullPattern);

  return urls?.map((url) => url.match(idPatternFromUrl)?.[0]) ?? [];
}

interface ItemCreateMessageFormStateOptions {
  control: Control<ItemCreateMessageFormData>;
  setValue: UseFormSetValue<ItemCreateMessageFormData>;
}

function useItemCreateMessageFormAddProductFromLink({
  control,
  setValue
}: ItemCreateMessageFormStateOptions) {
  const [detectedProductIds, setDetectedProductIds] = useState<string[]>([]);

  const currentUser = useCurrentUser();

  const selectedProductIdsField = useWatch({
    control,
    name: MessageFields.SELECTED_PRODUCT_IDS
  });

  const handleChangeSelectedProducts = useCallback(
    (productsSetByUserId: FetchProductsSetByUserIdQueryResponse) => {
      const uniqueDetectedProduct = filter(
        productsSetByUserId?.selectedProducts,
        (selectedProduct) =>
          !selectedProductIdsField.includes(selectedProduct.id) &&
          detectedProductIds.includes(selectedProduct.product.nanoId)
      );

      const selectedProductIds = map(uniqueDetectedProduct, 'id');

      if (size(selectedProductIds) > 0) {
        setValue(MessageFields.SELECTED_PRODUCT_IDS, [
          ...selectedProductIdsField,
          ...selectedProductIds
        ]);
      }
    },
    [detectedProductIds, selectedProductIdsField, setValue]
  );

  const resetDetectedProduct = useCallback(() => {
    size(detectedProductIds) > 0 && setDetectedProductIds([]);
  }, [detectedProductIds]);

  const { toggleProductInProductsSetErrorMessage, toggleProductInProductsSet } =
    useToggleProductInProductsSet({
      query: TOGGLE_PRODUCT_IN_PRODUCTS_SET_QUERY,
      cacheKeys: [ProductsSetCache.showCacheKey()]
    });

  const { productsSetError, productsSet } =
    useProductsSetByUserId<FetchProductsSetByUserIdQueryResponse>({
      cacheKey: ProductsSetCache.showCacheKey(),
      query: FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
      userId: currentUser.get('id'),
      options: {
        onSuccess: (data) =>
          handleChangeSelectedProducts(data?.productsSetByUserId)
      }
    });

  useShowToastOnErrorChange({
    error: productsSetError || toggleProductInProductsSetErrorMessage
  });

  const detectProduct = useCallback(
    (lexical: LexicalFieldValueType) => {
      const foundProductIds = findProductIds(lexical?.text || '');

      const uniqFoundProductIds = uniq(foundProductIds);

      const selectedProducts = productsSet?.selectedProducts;

      if (!isEqual(detectedProductIds, uniqFoundProductIds)) {
        for (const productId of uniqFoundProductIds) {
          if (!detectedProductIds.includes(productId)) {
            const selectedProduct = selectedProducts?.find(
              (selectedProduct) => selectedProduct.product.nanoId === productId
            );

            if (selectedProduct) {
              setValue(MessageFields.SELECTED_PRODUCT_IDS, [
                ...selectedProductIdsField,
                selectedProduct.id
              ]);
            } else {
              // add new products
              toggleProductInProductsSet({
                uuid: productsSet?.uuid,
                productId
              });
            }
          }
        }
      }

      setDetectedProductIds(uniqFoundProductIds);
    },
    [
      detectedProductIds,
      productsSet?.selectedProducts,
      productsSet?.uuid,
      selectedProductIdsField,
      setValue,
      toggleProductInProductsSet
    ]
  );

  return {
    detectProduct,
    resetDetectedProduct
  };
}

export default useItemCreateMessageFormAddProductFromLink;
