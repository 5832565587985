import React from 'react';
import cl from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  FetchMessagesCacheKey,
  MessageClassifications,
  MessageID,
  MessageProjectUUID,
  MessageTaskUUID
} from '../../../../../messagesTypes';
import { ItemMessagesListMessageForwardedMessageMessage } from './ItemMessagesListMessageForwardedMessage.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ItemMessagesListMessageForwardedMessageColors } from './components/ItemMessagesListMessageForwardedMessageColors';
import { ItemMessagesListMessageMenu } from '../ItemMessagesListMessageMenu';

import { Icon } from '../../../../../../../helpers/Icon';
import { MarkdownHelper } from '../../../../../../../helpers/MarkdownHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { MessagesPermissions } from '../../../../../messagesConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

type ItemMessagesListMessageForwardedMessageProps = {
  message: ItemMessagesListMessageForwardedMessageMessage;
  onReplyMessage?: (messageId: MessageID) => void;
  reverse?: boolean;
  messagesCacheKey: FetchMessagesCacheKey;
  forwardMessageProjectUuid: MessageProjectUUID;
  forwardMessageTaskUuid?: MessageTaskUUID;
  markedUnhappyMessages?: boolean;
} & CheckableMessagesProps;

function ItemMessagesListMessageForwardedMessage({
  message,
  reverse,
  messagesCacheKey,
  onReplyMessage,
  forwardMessageProjectUuid,
  forwardMessageTaskUuid,
  checkedMessages,
  onSetCheckedMessage,
  markedUnhappyMessages
}: ItemMessagesListMessageForwardedMessageProps) {
  const currentUser = useCurrentUser();

  if (!message.forwardedMessage) {
    return null;
  }

  const withoutBody =
    isEmpty(message.body) &&
    isEmpty(message.todoItems) &&
    !isEmpty(message.forwardedMessage.body);

  const unhappy =
    (message.forwardedMessage.classification ===
      MessageClassifications.UNHAPPY ||
      message.forwardedMessage.classification ===
        MessageClassifications.MOST_LIKELY_UNHAPPY) &&
    markedUnhappyMessages &&
    !message.forwardedMessage.aiNotRelevantAt;

  return (
    <div
      className={cl(
        reverse
          ? 'flex justify-end ml-12 sm:ml-16 mr-3'
          : 'flex justify-start mr-3 sm:mr-7 ml-3'
      )}
    >
      <div
        className={cl(
          'flex opacity-75 hover:opacity-90 focus-within:opacity-90',
          {
            '-mb-4': isEmpty(message.forwardedMessage.colors),
            'justify-end': reverse
          }
        )}
      >
        <div className="flex flex-col">
          <div
            className={cl(
              'text-xs mb-0.5 ml-2 text-gray-600 dark:text-gray-400',
              message.forwardedMessage.body ? 'mb-0.5' : 'pb-4'
            )}
          >
            <Icon
              icon={IconsEnum.ARROW_RIGHT_SOLID}
              className="w-3.5 h-3.5 inline mr-1"
            />
            <Translate id={messagesKeys.forwardedFrom} />{' '}
            {message.forwardedMessage.user?.fullName}
          </div>
          <div className="w-full">
            <div
              className={cl('flex', reverse ? 'justify-end' : 'justify-start')}
            >
              <div className="flex flex-col items-end">
                {isEmpty(message.forwardedMessage.body) ? null : (
                  <div
                    className={cl(
                      'inline-flex px-3 py-2 rounded-xl relative text-xs max-w-xl',
                      {
                        'border border-gray-200 dark:border-gray-800 rounded-bl':
                          !message.forwardedMessage.visibleForClient &&
                          !message.forwardedMessage.user?.client,
                        'bg-blue-100 dark:bg-gray-300 dark:text-black':
                          message.forwardedMessage.visibleForClient &&
                          !message.forwardedMessage.user?.client,
                        'bg-blue-100 border-blue-500 border-l-8 dark:text-black':
                          message.forwardedMessage.visibleForClient &&
                          message.forwardedMessage.user?.client &&
                          !unhappy,
                        'bg-red-100 border-red-500 border-l-8 dark:text-black':
                          message.forwardedMessage.visibleForClient &&
                          message.forwardedMessage.user?.client &&
                          unhappy,
                        'pb-6': isEmpty(message.forwardedMessage.colors)
                      }
                    )}
                  >
                    {message.forwardedMessage.visibleForClient &&
                      message.forwardedMessage.user?.client &&
                      unhappy && (
                        <div className="absolute flex -left-6 -top-0.5 text-red-500 rounded-full bg-white dark:bg-gray-900">
                          <Icon icon={IconsEnum.EMOJI_SAD_SOLID} />
                        </div>
                      )}

                    <MarkdownHelper
                      source={message.forwardedMessage.body}
                      className="break-wrap markdown markdown-sm sm:markdown-md markdown-pre:whitespace-pre-wrap leading-snug"
                      fromMessage
                      linkTarget={
                        currentUser.hasPermissions(
                          MessagesPermissions.READ_MESSAGE_LINK_BLANK
                        )
                          ? '_blank'
                          : undefined
                      }
                    />
                  </div>
                )}
                {isEmpty(message.forwardedMessage.colors) ? null : (
                  <ItemMessagesListMessageForwardedMessageColors
                    message={message.forwardedMessage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {withoutBody && (
        <ItemMessagesListMessageMenu
          message={message}
          messagesCacheKey={messagesCacheKey}
          onReplyMessage={onReplyMessage}
          isLeft={reverse}
          withoutPropagateButton
          forwardMessageProjectUuid={forwardMessageProjectUuid}
          forwardMessageTaskUuid={forwardMessageTaskUuid}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
        />
      )}
    </div>
  );
}

export default ItemMessagesListMessageForwardedMessage;
